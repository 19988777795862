import './index.sass';
import { useState } from 'react';

import { Bg } from '../Bg';
import { Landing } from '../Landing';
import { SelectMode } from '../SelectMode';
import { SelectBoxScene } from '../SelectBoxScene';
import { SelectGroundScene } from '../SelectGroundScene';
import { motion, AnimatePresence } from 'framer-motion';
import { useAppStore } from '@/stores/app';

export const HomePage = (props) => {
  const [page, setPage] = useState('landing');

  const setMode = useAppStore((state) => state.setMode);
  const setScene = useAppStore((state) => state.setScene);

  return (
    <motion.div
      className="HomePage page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <Bg page={page} />

      <AnimatePresence>
        {page == 'landing' && (
          <Landing
            onStart={() => {
              props.onStart?.();
              setPage('select-mode');
            }}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {page == 'select-mode' && (
          <SelectMode
            onModeClick={(mode) => {
              setMode(mode);
              switch (mode) {
                case 'box':
                  setPage('select-box-scene');
                  break;
                case 'ground':
                  setPage('select-ground-scene');
                  break;
              }
            }}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {page == 'select-box-scene' && (
          <SelectBoxScene
            onSceneClick={(scene) => {
              setMode('box');
              setScene(scene);
              props.onShow?.();
            }}
            onBack={(scene) => {
              setPage('select-mode');
            }}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {page == 'select-ground-scene' && (
          <SelectGroundScene
            onSceneClick={(scene) => {
              setMode('ground');
              setScene(scene);
              props.onShow?.();
            }}
            onBack={(scene) => {
              setPage('select-mode');
            }}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};
