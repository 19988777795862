import { useRef, useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { Hud, Plane, OrthographicCamera } from '@react-three/drei';
import gsap from 'gsap';
import { getUrlBoolean } from '@/helpers/UrlParam';

const flash = getUrlBoolean('flash', false);

export const LoopFlash = function (props) {
  const { size } = useThree();
  const refFlashMaterial = useRef(null);

  useEffect(() => {
    if (!flash) return;
    const mat = refFlashMaterial.current;
    const mixer = props.mixer.current;

    const onLoop = () => {
      if (!mat) return;
      gsap.fromTo(mat, { opacity: 1 }, { opacity: 0, duration: 1 / 60 });
    };

    if (mixer) {
      mixer.addEventListener('loop', onLoop);
    }
    return () => {
      if (mixer) {
        mixer.removeEventListener('loop', onLoop);
      }
    };
  }, [
    props.mixer,
    props.mixer.current,
    refFlashMaterial,
    refFlashMaterial.current,
  ]);

  return (
    <>
      {flash && (
        <Hud renderPriority={4}>
          <OrthographicCamera
            makeDefault
            position={[0, 0, 100]}
          />

          {/* LOCKUP */}
          <Plane args={[size.width, size.height]}>
            <meshBasicMaterial
              color="red"
              transparent={true}
              opacity={0}
              ref={refFlashMaterial}
            />
          </Plane>
        </Hud>
      )}
    </>
  );
};
