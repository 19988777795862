import './index.sass';
import { useAppStore } from '@/stores/app';
import { motion } from 'framer-motion';
import * as paths from '@/config/paths';
import Env from '@/helpers/Env';

export const ScaleSlider = (props) => {
  const setScene = useAppStore((state) => state.setScene);

  return (
    <div className="ScaleSlider">
      {/* <div className="labels">
        
      </div> */}
      <div className="label">大</div>
      <div className="wrapper">
        <input
          className="scale-input"
          type="range"
          onChange={props.onChange}
          min="0.1"
          max="2"
          step="0.1"
          value={props.scale}
        />
      </div>
      <div className="label">小</div>
    </div>
  );
};
