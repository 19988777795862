import React, {
  useRef,
  useMemo,
  useContext,
  createContext,
  useEffect,
} from 'react';
import {
  useGLTF,
  Merged,
  PerspectiveCamera,
  PositionalAudio,
} from '@react-three/drei';
import { useThree, useFrame, invalidate } from '@react-three/fiber';
import {
  Plane,
  Vector3,
  AnimationMixer,
  Quaternion,
  MeshStandardMaterial,
  MeshDistanceMaterial,
} from 'three';
import * as paths from '@/config/paths';
import { AssetsContext } from '@/components/ar/Assets';
import { useAnimations } from '@/components/ar/utils/useAnimations';
import { useAudioLoop } from '@/components/ar/utils/useAudioLoop';
import { LoopFlash } from '@/components/ar/utils/LoopFlash';
import * as colors from '@/config/colors';

const path = paths.box_speeder;

export function SpeederModel(props) {
  const { gl, invalidate } = useThree();
  gl.localClippingEnabled = true;

  const group = useRef();
  const { scene, nodes, materials, animations } = useGLTF(path);
  // const assetContext = useContext(AssetsContext);
  // const { scene, nodes, materials, animations } = assetContext.models.box_speeder;

  const { mixer, action } = useAnimations(group, animations, 1.15);
  const refAudio = useRef(null);
  useAudioLoop(refAudio, mixer);

  // **************************************************
  // CLIPPING PLANE
  // **************************************************

  // const materials = useRef({});
  // useEffect(() => {
  //   if (!props.clip) return;
  //   for (let id in originalMaterials) {
  //     materials[id] = originalMaterials[id].clone();
  //   }
  // }, [originalMaterials]);

  const clippingPlanes = useMemo(
    () => [
      new Plane(new Vector3(1, 0, 0), 0.35),
      new Plane(new Vector3(-1, 0, 0), 0.35),
    ],
    []
  );

  const originalPlanes = useMemo(
    () => [
      new Plane(new Vector3(1, 0, 0), 0.35),
      new Plane(new Vector3(-1, 0, 0), 0.35),
    ],
    []
  );

  const worldPosition = useMemo(() => new Vector3(), []);
  const worldQuaternion = useMemo(() => new Quaternion(), []);

  useFrame((state, delta) => {
    group.current.getWorldPosition(worldPosition);
    group.current.getWorldQuaternion(worldQuaternion);
    for (let idx = 0; idx < clippingPlanes.length; idx++) {
      let plane = clippingPlanes[idx];
      plane.copy(originalPlanes[idx]);
      plane.applyMatrix4(group.current.matrixWorld);
    }
  });

  const envMapIntensity = 0.4;
  // const envMapIntensity = 0;

  const clippingMaterial = useMemo(() => {
    let material = new MeshStandardMaterial({
      map: materials['Material #27'].map,
    });

    material.envMapIntensity = envMapIntensity;
    material.clippingPlanes = clippingPlanes;

    return material;
  }, [materials, clippingPlanes]);

  // **************************************************
  // MATERIALS
  // **************************************************
  useEffect(() => {
    for (let id in materials) {
      // materials[id].roughness = 0;
      // materials[id].metalness = 0;
      materials[id].envMapIntensity = envMapIntensity;
    }
  }, [materials]);

  // **************************************************
  // RETURN
  // **************************************************
  return (
    <group
      ref={group}
      {...props}
      dispose={null}
    >
      <PositionalAudio
        ref={refAudio}
        url={paths.audio_box_speeder}
        distance={10000}
      />
      <LoopFlash mixer={mixer} />
      <group>
        <group
          name="trees"
          position={[-0.0905, 0.3749, 0.1069]}
          rotation={[-0.0008, 0.0019, 0.0011]}
          scale={3.6907}
        >
          <group
            name="Group001"
            position={[-0.0525, -0.0304, 0.0203]}
            rotation={[-0.0075, -0.3482, 0.0133]}
          >
            <mesh
              name="m48156_F"
              castShadow
              receiveShadow
              geometry={nodes.m48156_F.geometry}
              material={materials['Material #27']}
              position={[0.0116, 0.0224, 0.0026]}
              rotation={[1.878, 0.2683, 1.133]}
              scale={0.3937}
            />
            <mesh
              name="m3815_J1"
              castShadow
              receiveShadow
              geometry={nodes.m3815_J1.geometry}
              material={materials['Material #27']}
              position={[0.0181, 0.0022, -0.0041]}
              rotation={[1.878, 0.2683, 1.133]}
              scale={0.3937}
            />
          </group>
          <group
            name="Group002"
            position={[0.064, -0.026, -0.0455]}
          >
            <mesh
              name="laser"
              castShadow
              receiveShadow
              geometry={nodes.laser.geometry}
              material={materials['Material #29']}
              position={[0.0095, -0.0122, -0.0056]}
              rotation={[2.2419, -0.2514, 1.2086]}
              scale={0.0925}
            >
              <meshStandardMaterial
                color={colors.lightsaberRed.color}
                emissive={colors.lightsaberRed.emissive}
                emissiveIntensity={2}
                toneMapped={false}
                clippingPlanes={clippingPlanes}
              />
            </mesh>
            <mesh
              name="laser001"
              castShadow
              receiveShadow
              geometry={nodes.laser001.geometry}
              material={materials['Material #29']}
              position={[0.0095, -0.0122, -0.0056]}
              rotation={[2.2419, -0.2514, 1.2086]}
              scale={0.0925}
            >
              <meshStandardMaterial
                color={colors.lightsaberRed.color}
                emissive={colors.lightsaberRed.emissive}
                emissiveIntensity={2}
                toneMapped={false}
                clippingPlanes={clippingPlanes}
              />
            </mesh>
            <mesh
              name="m3626_N2"
              castShadow
              receiveShadow
              geometry={nodes.m3626_N2.geometry}
              material={materials['Material #27']}
              position={[0.0195, 0.0163, 0.0154]}
              rotation={[2.4032, 0.0246, 1.2209]}
              scale={0.3937}
            >
              <mesh
                name="m64803_D1"
                castShadow
                receiveShadow
                geometry={nodes.m64803_D1.geometry}
                material={materials['Material #27']}
                position={[0, 0.0013, -0.0042]}
                rotation={[-0.197, -0.0305, -0.0381]}
              />
            </mesh>
            <mesh
              name="m3626_N"
              castShadow
              receiveShadow
              geometry={nodes.m3626_N.geometry}
              material={materials['Material #27']}
              position={[0.042, 0.0214, 0.0088]}
              rotation={[2.2906, -0.2764, 1.2066]}
              scale={0.3937}
            >
              <mesh
                name="m64803_D"
                castShadow
                receiveShadow
                geometry={nodes.m64803_D.geometry}
                material={materials['Material #27']}
                position={[0, 0.0013, -0.0042]}
                rotation={[-0.2004, -0.0337, -0.0466]}
              />
            </mesh>
            <mesh
              name="m36840_D1"
              castShadow
              receiveShadow
              geometry={nodes.m36840_D1.geometry}
              material={materials['Material #27']}
              position={[-0.0019, -0.0057, -0.0078]}
              rotation={[2.3333, -0.2922, 1.2082]}
              scale={0.3937}
            />
          </group>
          <group
            name="Group011"
            position={[-0.5544, 0, -0.0147]}
            rotation={[0.0008, -0.0019, -0.0011]}
          >
            <group
              name="Group004"
              position={[0.5606, -0.002, -0.0156]}
            >
              <group
                name="grass"
                position={[0.0165, -0.0816, 0.0013]}
              >
                <mesh
                  name="m2682_A"
                  castShadow
                  receiveShadow
                  geometry={nodes.m2682_A.geometry}
                  material={clippingMaterial}
                  position={[0.0384, 0.0039, 0.0131]}
                  rotation={[Math.PI / 2, 0, 0]}
                  scale={0.3937}
                />
              </group>
            </group>
            <mesh
              name="Object001"
              castShadow
              receiveShadow
              geometry={nodes.Object001.geometry}
              material={clippingMaterial}
              position={[0.6155, -0.0798, -0.0012]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={0.3937}
            />
          </group>
        </group>
        <PerspectiveCamera
          name="Camera001"
          makeDefault={false}
          far={10000}
          fov={26.2313}
          position={[-0.0113, 0, 2.1533]}
        />
        <mesh
          name="m2412_C7"
          castShadow
          receiveShadow
          geometry={nodes.m2412_C7.geometry}
          material={materials['Material #27']}
          position={[-0.2331, 0.0329, -0.2715]}
          rotation={[1.57, 0.0011, -0.0019]}
          scale={1.453}
        />
      </group>
    </group>
  );
}
