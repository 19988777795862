import { useMemo, useEffect, useRef, useState } from 'react';
import {
  Texture,
  WebGLCubeRenderTarget,
  LinearMipmapLinearFilter,
  RGBAFormat,
  MeshBasicMaterial,
  CubeCamera,
  sRGBEncoding,
  DoubleSide,
  Scene,
  SphereGeometry,
  Mesh,
} from 'three';
import { useThree } from '@react-three/fiber';
import { useTexture } from '@react-three/drei';

const camTexture_ = new Texture();
const renderTarget = new WebGLCubeRenderTarget(256, {
  format: RGBAFormat,
  generateMipmaps: true,
  minFilter: LinearMipmapLinearFilter,
  encoding: sRGBEncoding,
});
const refMat = new MeshBasicMaterial({
  side: DoubleSide,
  color: 0xffffff,
  map: camTexture_,
});
// cubemap scene
const cubeMapScene = new Scene();
const cubeCamera = new CubeCamera(1, 1000, renderTarget);
const sphere = new SphereGeometry(100, 15, 15);
const sphereMesh = new Mesh(sphere, refMat);
sphereMesh.scale.set(-1, 1, 1);
sphereMesh.rotation.set(Math.PI, -Math.PI / 2, 0);
cubeMapScene.add(sphereMesh);

export function XR8_Environment() {
  const { gl, scene } = useThree();

  const module = useMemo(() => {
    const onAttach = function () {
      scene.environment = cubeCamera.renderTarget.texture;
      // scene.background = cubeCamera.renderTarget.texture;
    };

    const onUpdate = function () {
      cubeCamera.update(gl, cubeMapScene);
    };

    const onProcessCpu = function ({ frameStartResult }) {
      const { cameraTexture } = frameStartResult;
      console.log(cameraTexture);
      const texProps = gl.properties.get(camTexture_);
      texProps.__webglTexture = cameraTexture;
    };

    return {
      name: `XR8_Environment`,
      onAttach,
      onUpdate,
      onProcessCpu,
    };
  }, [gl, cubeCamera, cubeMapScene, camTexture_]);

  useEffect(() => {
    XR8.addCameraPipelineModule(module);

    return () => {
      XR8.removeCameraPipelineModule(module.name);
    };
  }, [module]);

  return <></>;
}
