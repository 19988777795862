import { useEffect, useLayoutEffect, useState } from 'react';
// import use8thWall from './hooks/use8thWall';
import { XR8_Failed } from '@/components/ar/xr8/XR8_Failed';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';

let initialized = false;

let videoEl;

export function XR8(props) {
  const appKey =
    't6PXP3tCQweqXWjv4YjOZtwo7uO47duYX4h9Wjs5MZSNXHc73EmVBz42tiR68QnqGfz0PI';

  // const { xr8, status } = use8thWall(appKey, props.canvas);

  const [xr8, setXr8] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    let interval = setInterval(() => {
      console.log('interval');

      if (videoEl) {
        console.log(videoEl, videoEl.paused);
        videoEl.play();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (initialized) return;
    initialized = true;
    console.log('use8thWall', appKey, props.canvas.current);

    const { XR8, XRExtras, CoachingOverlay } = window;
    CoachingOverlay.configure({
      animationColor: '#F9D132',
      promptText: '',
      disablePrompt: true,
    });

    XR8.XrController.configure({
      enableLighting: false,
      scale: 'absolute',
    });

    XR8.addCameraPipelineModules([
      XR8.GlTextureRenderer.pipelineModule(),
      XR8.XrController.pipelineModule(),
      XRExtras.AlmostThere.pipelineModule(),
      XRExtras.RuntimeError.pipelineModule(),
      XRExtras.PauseOnHidden.pipelineModule(),
      CoachingOverlay.pipelineModule(),
      {
        name: 'XR8_CameraStatus',
        onCameraStatusChange: ({ status, video }) => {
          console.log('onCameraStatusChange', status, video);
          videoEl = video;
          setStatus(status);
        },
      },
    ]);
    XR8.run({ canvas: props.canvas.current });

    setXr8(XR8);

    return () => {
      XR8.pause();
      XR8.stop();
    };
  }, []);

  return (
    <>
      {xr8 && <>{props.children}</>}
      {createPortal(
        <AnimatePresence>
          {status == 'failed' && <XR8_Failed />}
        </AnimatePresence>,
        document.body
      )}
    </>
  );
}
