import { DoubleSide } from 'three';
import { Plane } from '@react-three/drei';

export function HiderWalls({ ratio, scale = 100 }) {
  return (
    <group>
      <Plane
        args={[ratio, 1]}
        scale={[scale, scale, 1]}
        position={[ratio * -(0.5 + scale / 2), 0, 0]}
        renderOrder={-Infinity}
      >
        <meshStandardMaterial
          colorWrite={false}
          side={DoubleSide}
        />
      </Plane>

      <Plane
        args={[ratio, 1]}
        scale={[scale, scale, 1]}
        position={[ratio * (0.5 + scale / 2), 0, 0]}
        renderOrder={-Infinity}
      >
        <meshStandardMaterial
          colorWrite={false}
          side={DoubleSide}
        />
      </Plane>

      <Plane
        args={[ratio, 1]}
        scale={[scale, scale, 1]}
        position={[0, -(0.5 + scale / 2), 0]}
        renderOrder={-Infinity}
      >
        <meshStandardMaterial
          colorWrite={false}
          side={DoubleSide}
        />
      </Plane>

      <Plane
        args={[ratio, 1]}
        scale={[scale, scale, 1]}
        position={[0, +(0.5 + scale / 2), 0, 0]}
        renderOrder={-Infinity}
      >
        <meshStandardMaterial
          colorWrite={false}
          side={DoubleSide}
        />
      </Plane>
    </group>
  );
}
