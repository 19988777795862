export const fov = 30;

const getDistanceForPerfectProjection = (fov) => {
  const height = 1;
  const vFov = fov * (Math.PI / 180);
  const z = height / (2 * Math.tan(vFov / 2));
  return z;
};

export const cameraZ = getDistanceForPerfectProjection(fov);
