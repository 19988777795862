import './index.sass';

import { TermsPopup } from '../TermsPopup';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import * as paths from '@/config/paths';

export const Landing = (props) => {
  const [showTerms, setShowTerms] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(true);

  const list = {
    exit: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
  };

  const listItem = {
    exit: {
      opacity: 0,
      y: 50,
    },
  };

  return (
    <div className="Landing page">
      <div className="top">
        <div className="pieces">
          <motion.img
            className="piece piece-red"
            src={paths.imgUiHomeLandingPieceRed}
            exit={{ opacity: 0, x: 20 }}
          />
          <motion.img
            className="piece piece-yellow"
            src={paths.imgUiHomeLandingPieceYellow}
            exit={{ opacity: 0, x: -25 }}
          />
          <motion.img
            className="piece piece-blue"
            src={paths.imgUiHomeLandingPieceBlue}
            exit={{ opacity: 0, x: -20 }}
          />
        </div>
      </div>
      <div className="center">
        <div className="minifigures">
          <motion.img
            className="luke"
            src={paths.imgUiHomeLandingLuke}
            exit={{ opacity: 0, x: 100 }}
          />
          <motion.img
            className="vader"
            src={paths.imgUiHomeLandingVader}
            exit={{ opacity: 0, x: -100 }}
          />
        </div>
      </div>

      <motion.div
        className="bottom"
        variants={list}
        exit="exit"
      >
        <motion.img
          className="lockup"
          src={paths.imgUiGeneralLockup}
          variants={listItem}
          exit="exit"
        />
        <motion.button
          variants={listItem}
          exit="exit"
        >
          <img
            className="start"
            src="assets/images/ui/home/landing/start.svg"
            onClick={() => {
              if (agreeTerms) {
                props.onStart();
              } else {
                setShowTerms(true);
              }
            }}
          />
        </motion.button>
        <motion.img
          className="logo-xr8"
          src="assets/images/ui/_general/logo-xr8-white.svg"
          variants={listItem}
          exit="exit"
        />
        {/* <motion.div
          className="terms"
          variants={listItem}
          exit="exit"
        >
          <button
            className={`checkbox ${agreeTerms ? 'checked' : ''}`}
            onClick={() => setAgreeTerms(!agreeTerms)}
          />
          同意
          <button
            className="link"
            onClick={() => {
              setShowTerms(true);
            }}
          >
            《用户隐私协议》
          </button>
        </motion.div> */}
      </motion.div>

      <AnimatePresence>
        {showTerms && (
          <TermsPopup
            onAgree={() => {
              setShowTerms(false);
              setAgreeTerms(true);
            }}
            onDisagree={() => {
              setShowTerms(false);
              setAgreeTerms(false);
            }}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
