import { useEffect, forwardRef, useMemo, useContext } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei';
import { Clock } from 'three';
import { fov, cameraZ } from '@/components/ar/utils/helpers';
import { CameraContext } from '@/components/ar/xr8/context/CameraContext';

let videoEl;
export const XR8_LayerCamera = (props) => {
  const ref = useContext(CameraContext);
  const { gl, invalidate } = useThree();

  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     console.log('interval');

  //     if (videoEl) {
  //       console.log(videoEl, videoEl.paused);
  //       videoEl.play();
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const module = useMemo(() => {
    // const onCameraStatusChange = ({ status, stream, video }) => {
    //   console.log(status, stream, video);
    //   videoEl = video;
    // };

    const onAttach = ({ canvas, canvasWidth, canvasHeight, GLctx }) => {
      // XR8.XrController.updateCameraProjectionMatrix({
      //   origin: ref.current.position,
      //   facing: ref.current.quaternion,
      // });
    };

    const updateCamera = function (result) {
      if (!result) return;
      if (!ref.current) return;

      ref.current.updateProjectionMatrix();
      const realitySource =
        result.reality || result.facecontroller || result.layerscontroller;
      if (!realitySource) {
        return;
      }
      const { rotation, position, intrinsics } = realitySource;
      for (let i = 0; i < 16; i++) {
        ref.current.projectionMatrix.elements[i] = intrinsics[i];
      }
      if (ref.current.projectionMatrixInverse) {
        if (ref.current.projectionMatrixInverse.invert) {
          ref.current.projectionMatrixInverse
            .copy(ref.current.projectionMatrix)
            .invert();
        } else {
          ref.current.projectionMatrixInverse.getInverse(
            ref.current.projectionMatrix
          );
        }
      }
      if (rotation) {
        ref.current.setRotationFromQuaternion(rotation);
      }
      if (position) {
        ref.current.position.set(position.x, position.y, position.z);
      }
    };

    const onUpdate = (evt) => {
      updateCamera(evt.processCpuResult);
    };

    const onCanvasSizeChange = ({ canvasWidth, canvasHeight }) => {
      if (!ref.current) return;
      ref.current.aspect = canvasWidth / canvasHeight;
      ref.current.updateProjectionMatrix();
    };

    const onRender = () => {
      gl.clearDepth();
      // invalidate();
    };
    return {
      name: 'XR8_Camera',
      onAttach,
      onUpdate,
      onCanvasSizeChange,
      onRender,
      // onCameraStatusChange,
    };
  }, [gl, invalidate, ref]);

  useEffect(() => {
    if (!XR8) return;
    XR8.addCameraPipelineModule(module);

    return () => {
      XR8.removeCameraPipelineModule(module.name);
    };
  }, [module]);

  return (
    <>
      <PerspectiveCamera
        name="XR8_Camera"
        className="XR8_Camera"
        ref={ref}
        fov={fov}
        position={[0, 0, cameraZ]}
        {...props}
      />
    </>
  );
};
