import { useRef, useEffect, useMemo } from 'react';

export function useAudioLoop(refAudio, mixer, enabled = true) {
  useEffect(() => {
    const audio = refAudio.current;

    const onLoop = () => {
      if (!audio) return;
      audio.stop();
      audio.offset = 0;
      audio.play();
    };

    onLoop();

    if (mixer.current) {
      mixer.current.addEventListener('loop', onLoop);
    }

    return () => {
      if (mixer.current) {
        mixer.current.removeEventListener('loop', onLoop);
      }
      if (audio) audio.stop();
    };
  }, [mixer.current, refAudio, refAudio.current]);

  useEffect(() => {
    if (!refAudio.current) return;
    // refAudio.current.setVolume(enabled ? 1 : 0);
    if (!enabled) {
      refAudio.current.stop();
    } else {
      refAudio.current.stop();
      refAudio.current.offset = 0;
      refAudio.current.play();
    }
  }, [enabled, refAudio, refAudio.current]);
}
