import './index.sass';
import { motion } from 'framer-motion';

export const Poster = (props) => {
  return (
    <div className="Poster">
      {props.url && (
        <motion.img
          className="hero"
          src={props.url}
        />
      )}

      <div className="ui">
        <button
          className="btn-back"
          onClick={() => props.onBack?.()}
        >
          <img src="assets/images/ui/_general/back.svg" />
        </button>
        <div className="cta">
          长按屏幕
          <br />
          分享给朋友
        </div>
      </div>

      <motion.div
        className="flash"
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{ delay: 0.1, duration: 1 }}
      />
    </div>
  );
};
