import { Popup } from '@/components/ui/_general/Popup';
import './index.sass';
import * as paths from '@/config/paths';

export const PopupHome = (props) => {
  const goToHome = function () {
    // TODO
    // let url = '/pages/subpages/product-hub/index?code=SW2023';
    let url = '/pages/subpages/product-hub/index?code=SWH5';
    if (!window.wx) return;
    console.log('goToHome', url);
    window.wx.miniProgram.navigateTo({ url });
    // window.wx.miniProgram.navigateTo({ url: 'pages/subpages/product-hub/index?code=SW2023' });
  };

  return (
    <Popup
      className="PopupHome"
      onClose={props.onClose}
    >
      <h3>
        探索更多乐高星球大战
        <br />
        玩乐体验
      </h3>
      <img
        className="icon"
        src={paths.imgUiPopupHome}
      />
      <button
        className="btn-primary"
        onClick={() => goToHome()}
      >
        好的
      </button>
      <button
        className="btn-secondary"
        onClick={props.onClose}
      >
        不，继续体验AR
      </button>
    </Popup>
  );
};
