import { createRoot } from 'react-dom/client';
import { useState } from 'react';
import { App } from './App.jsx';
import VConsole from 'vconsole';
import { getUrlBoolean } from '@/helpers/UrlParam';

import 'uno.css';
import '@/styles/shared.sass';
import '@/styles/core.sass';
import '@/styles/app/index.sass';

const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer);

root.render(<App />);

console.log('index');

let inDom = false;
const observer = new MutationObserver(() => {
  if (document.querySelector('.prompt-box-8w')) {
    if (!inDom) {
      document.querySelector('.prompt-box-8w p').innerHTML =
        '使用AR需要访问摄像头';
      document.querySelector('.prompt-button-8w').innerHTML = '取消';
      document.querySelector('.button-primary-8w').innerHTML = '继续';
    }
    inDom = true;
  } else if (inDom) {
    inDom = false;
    observer.disconnect();
  }
});
observer.observe(document.body, { childList: true });

const debug = getUrlBoolean('debug', false);
if (debug) {
  const vConsole = new VConsole();
}
