import './index.sass';
import { motion } from 'framer-motion';

export const Popup = (props) => {
  return (
    <div className={`Popup ${props.className}`}>
      <motion.div
        className="bg"
        onClick={props.onClose}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.1 } }}
      />
      <motion.div
        className="panel"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.1 } }}
      >
        {props.children}
      </motion.div>
    </div>
  );
};
