import './index.sass';
import { motion } from 'framer-motion';

export const TermsPopup = (props) => {
  return (
    <motion.div
      className="TermsPopup"
      initial={{ y: '100%' }}
      animate={{ y: '0%' }}
      exit={{ y: '100%' }}
    >
      <h2 className="title">用户隐私协议</h2>
      <div className="body">
        <p>
          欢迎您使用乐高App!为了更好的保护您的权益，请您在使用我们的产品或服务前，仔细阅读我们的《用户协议》及《隐私政策》，以帮助您更好的了解我们对于您的个人信息的保护情况，请您充分阅读并接受全部内容后点击同意。
        </p>

        <p>
          1.当您注册、登录乐高账号时，我们将收集您手机号码、验证码信息，您可查阅
        </p>

        <p>《个人信息收集清单》快速了解我们收集和使用您个人信息的情况。</p>

        <p>
          l二T么七人f心出册、登录乐高账号时，我们将收集您手机号码、验证码信息，您可查阅1.当您注册、登录乐高账号时，我们将收集您手机号码、验证码信息，您可查阅
        </p>

        <p>《个人信息收集清单》快速了解我们收集和使用您、验证码信</p>
      </div>
      <button
        className="btn-primary btn-agree"
        onClick={props.onAgree}
      >
        同意
      </button>
      <button
        className="btn-disagree"
        onClick={props.onDisagree}
      >
        不同意
      </button>
    </motion.div>
  );
};
