import { Suspense, useContext } from 'react';
import { useMask, Environment, Stage } from '@react-three/drei';
import { MathUtils } from 'three';
import { EffectComposer, Bloom, Vignette } from '@react-three/postprocessing';

import { XwingGroundModel } from './XwingGroundModel-primitive';
import { Ground } from '@/components/ar/Ground';
import { XR8_Environment } from '@/components/ar/xr8/XR8_Environment';
import { useLightPos } from '@/components/ar/utils/useLightPos.jsx';
import Env from '@/helpers/Env';

const presets = {
  rembrandt: {
    main: [1, 2, 1],
    fill: [-2, -0.5, -2],
  },
  portrait: {
    main: [-1, 2, 0.5],
    fill: [-1, 0.5, -1.5],
  },
  upfront: {
    main: [0, 2, 1],
    fill: [-1, 0.5, -1.5],
  },
  soft: {
    main: [-2, 4, 4],
    fill: [-1, 0.5, -1.5],
  },
};

export function XwingGroundScene(props) {
  // *******************************************************
  // SPOT LIGHT
  // *******************************************************
  const intensity = 1;
  const config = presets.soft;
  let radius = 6;
  const shadows = 'contact';
  const shadowBias = -0.0001;
  const normalBias = 0;
  const shadowSize = 2048;
  const shadowOffset = 0;

  const { XR8 } = window;

  const ref = useRef(null);
  // const refSpotLight = useLightPos(ref, radius, [-0.5, 1, 0.5]);
  // const refPointLight = useLightPos(ref, radius, [-0.1, 0.175, -1]);

  let scale;
  let z;
  switch (props.size) {
    case 's':
      scale = 0.5; // 1m
      z = 0;
      break;
    case 'm':
      scale = 1; // 2m
      z = 0;
      break;
    case 'l':
      scale = 13.4 / 2; // 13.4m
      z = 0;
      break;
    default:
      scale = 1;
      z = 0;
      break;
  }

  radius *= scale;

  return (
    <group ref={ref}>
      <group
        position={[0, 0, z]}
        rotation={[0, MathUtils.degToRad(90), 0]}
        scale={[scale, scale, scale]}
      >
        <XwingGroundModel enabled={props.enabled} />
      </group>
      <Ground />

      {/* {Env.mobile ? <XR8_Environment /> : <Environment preset={'city'} />} */}

      <spotLight
        penumbra={1}
        intensity={intensity}
        castShadow={true}
        shadow-bias={shadowBias}
        shadow-normalBias={normalBias}
        shadow-mapSize={shadowSize}
        position={[
          0 * radius,
          1.5 * radius,
          2 * radius,
          // config.main[0] * radius,
          // config.main[1] * radius,
          // config.main[2] * radius,
        ]}
      />
      <pointLight
        position={[
          -0.1 * radius,
          0.175 * radius,
          -1 * radius,
          // config.fill[0] * radius,
          // config.fill[1] * radius,
          // config.fill[2] * radius,
        ]}
        intensity={intensity / 3}
      />
    </group>
  );
}
