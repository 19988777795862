import { useEffect, useMemo } from 'react';
import { Vector2, MathUtils } from 'three';
import { useThree, useFrame } from '@react-three/fiber';
import { LogEase } from '@/helpers/LogEase';

import { ThroneFrame } from '@/components/ar/scenes/throne/ThroneFrame';
import { gsap } from 'gsap';

export function XR8_AbsoluteScale(props) {
  const { invalidate, camera, raycaster } = useThree();

  // const scale = props.foundScale ? 1 : 0.001;
  // refPlace.current.scale.set(scale, scale, scale);

  const module = useMemo(() => {
    const onAttach = function (evt) {
      // camera.position.set(0, 1.6, 2);
      // XR8.XrController.updateCameraProjectionMatrix({
      //   origin: camera.position,
      //   facing: camera.quaternion,
      // });
      refPlace.current.scale.set(0.001, 0.001, 0.001);
    };

    const onOverlayShow = function (evt) {
      refPlace.current.scale.set(0.001, 0.001, 0.001);
      props.onScaleLost?.();
    };

    const onOverlayHide = function (evt) {
      refPlace.current.scale.set(1, 1, 1);
      props.onScaleFound?.();
    };

    return {
      name: `XR8_AbsoluteScale`,
      onAttach,
      listeners: [
        { event: 'coaching-overlay.show', process: onOverlayShow },
        { event: 'coaching-overlay.hide', process: onOverlayHide },
      ],
    };
  }, [camera, props]);

  useEffect(() => {
    XR8.addCameraPipelineModule(module);
    window.addEventListener('click', onClick);

    return () => {
      XR8.removeCameraPipelineModule(module.name);
      window.removeEventListener('click', onClick);
    };
  }, [module]);

  const refGround = useRef(null);
  const refPlace = useRef(null);

  const onClick = function () {
    // XR8.XrController.recenter();
  };

  return (
    <group>
      {/* <mesh position={[0, 0, 0]}>
        <boxGeometry args={[1000, 2, 1000]} />
        <meshBasicMaterial
          color={'green'}
          transparent={true}
          opacity={0.4}
        />
      </mesh> */}
      <group ref={refPlace}>{props.children}</group>
    </group>
  );
}
