import { useState, useRef } from 'react';
import { MathUtils, DoubleSide } from 'three';
import {
  OrbitControls,
  Environment,
  PerspectiveCamera,
  useMask,
  Stars,
  RenderTexture,
  PivotControls,
  Grid,
  Image,
  Hud,
} from '@react-three/drei';

import { useThree, useFrame } from '@react-three/fiber';

import {
  EffectComposer,
  Bloom,
  Vignette,
  SSAO,
  SSR,
} from '@react-three/postprocessing';
import { useControls } from 'leva';
import Env from '@/helpers/Env';

import { Reflector } from '@/components/ar/Reflector';

import { ThroneModel } from './ThroneModel';
import { ThroneFrame } from './ThroneFrame';
import { DemoBox } from '@/components/ar/xr8/DemoBox';
import * as paths from '@/config/paths';
import { useLightPos } from '@/components/ar/utils/useLightPos.jsx';

const presets = {
  rembrandt: {
    main: [1, 2, 1],
    fill: [-2, -0.5, -2],
  },
  portrait: {
    main: [-1, 2, 0.5],
    fill: [-1, 0.5, -1.5],
  },
  upfront: {
    main: [0, 2, 1],
    fill: [-1, 0.5, -1.5],
  },
  soft: {
    main: [-2, 4, 4],
    fill: [-1, 0.5, -1.5],
  },
};

export function ThroneScene() {
  // *******************************************************
  // STENCIL
  // *******************************************************
  // const stencil = useMask(1);

  const width = 2048;
  const height = 1383;
  const ratio = width / height;

  // *******************************************************
  // SPOT LIGHT
  // *******************************************************
  const config = presets.soft;
  const intensity = 1;
  const radius = 3;
  const shadowBias = -0.0001;
  const shadowSize = 2048;

  const ref = useRef(null);
  // const refSpotLight = useLightPos(ref, radius, [1, 0.28, 1.16]);
  // const refPointLight = useLightPos(ref, radius, [1.36, 1.05, -2.5]);

  return (
    <group
      position={[0, -0.5, 0]}
      ref={ref}
    >
      <mesh rotation={[Math.PI * 0.5, 0, 0]}>
        <sphereGeometry args={[32, 32, 32, 0, Math.PI * 2, Math.PI * 0.5]} />
        <meshBasicMaterial
          color={'#000000'}
          side={DoubleSide}
        />
      </mesh>

      <Image
        url={paths.imgArThroneThroneBg}
        position={[0, 0.5, -1.5]}
        scale={[2, 2, 2]}
      />
      <group
        position={[0, 0.29, 0]}
        rotation={[
          MathUtils.degToRad(12),
          MathUtils.degToRad(-26),
          MathUtils.degToRad(0),
        ]}
        scale={[0.7, 0.7, 0.7]}
      >
        <ThroneModel />
      </group>

      <ThroneFrame />

      <Environment preset={'city'} />

      {/* <PivotControls annotations>
        <spotLight
          penumbra={1}
          intensity={intensity}
          castShadow={true}
          shadow-bias={shadowBias * 0.1}
          shadow-camera-near={0.01}
          shadow-mapSize-width={shadowSize}
          shadow-mapSize-height={shadowSize}
        />
      </PivotControls> */}

      <spotLight
        penumbra={1}
        intensity={intensity}
        castShadow={true}
        shadow-bias={shadowBias * 0.1}
        shadow-camera-near={0.01}
        shadow-mapSize-width={shadowSize}
        shadow-mapSize-height={shadowSize}
        position={[-0.4 * radius, 1 * radius, 0.4 * radius]}
      />

      <pointLight
        intensity={intensity}
        position={[
          config.fill[0] * radius,
          config.fill[1] * radius,
          config.fill[2] * radius,
        ]}
      />
    </group>
  );
}
