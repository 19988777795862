import './index.sass';

export const Debugger = (props) => {
  return (
    <ul className="Debugger">
      <li>
        <div>mode:</div>
        <div>{props.mode}</div>
      </li>
      <li>
        <div>scene:</div>
        <div>{props.scene}</div>
      </li>
      <li>
        <div>foundScale:</div>
        <div className={props.foundScale ? 'true' : 'false'}>
          {props.foundScale ? 'true' : 'false'}
        </div>
      </li>
      <li>
        <div>placed:</div>
        <div className={props.placed ? 'true' : 'false'}>
          {props.placed ? 'true' : 'false'}
        </div>
      </li>
      <li>
        <div>showLuke:</div>
        <div className={props.showLuke ? 'true' : 'false'}>
          {props.showLuke ? 'true' : 'false'}
        </div>
      </li>
    </ul>
  );
};
