import { Suspense, useState } from 'react';
import { ArScene } from '@/components/ar/ArScene';
import './App.sass';

import { MemoryRouter, Routes, Route } from 'react-router-dom';
import { TheLoading } from '@/components/ui/_general/TheLoading';

import { HomePage } from '@/components/ui/home/HomePage';
import { AssetsProvider } from '@/components/ar/Assets';

import { getUrlBoolean } from '@/helpers/UrlParam';

const skip = getUrlBoolean('skip');

export function App() {
  const [started, setStarted] = useState(skip);
  const [showAr, setShowAr] = useState(skip);

  return (
    <div
      id="app"
      className="app bg-black"
    >
      <Suspense fallback={<TheLoading />}>
        {/* <AssetsProvider> */}
        <HomePage
          onStart={() => {
            setStarted(true);
            // setShowAr(true);
          }}
          onShow={() => {
            setShowAr(true);
          }}
        />
        {started && (
          <ArScene
            show={showAr}
            onBack={() => {
              setShowAr(false);
            }}
          />
        )}
        {/* </AssetsProvider> */}
      </Suspense>
    </div>
  );
}
