import { useThree } from '@react-three/fiber';
import {
  Hud,
  OrthographicCamera,
  Image,
  Plane,
  useTexture,
} from '@react-three/drei';
import { useMediaQuery } from 'react-responsive';
import * as paths from '@/config/paths';

export const PosterOverlay = (props) => {
  const { size } = useThree();
  const header = useTexture(paths.imgUiArHeaderPortrait);
  const headerLandscape = useTexture(paths.imgUiArHeaderLandscape);
  const mpQr = useTexture(paths.imgUiArLegoMpQr);
  const rotj40 = useTexture(paths.imgUiArRotj40);

  const isLandscape = useMediaQuery({ minAspectRatio: 1 });

  const landscape = window.innerWidth > window.innerHeight;

  const scale = 1;

  return (
    <Hud renderPriority={3}>
      <OrthographicCamera
        makeDefault
        position={[0, 0, 100]}
      />

      {/* LOCKUP */}
      {!landscape ? (
        <group>
          <Plane
            args={[278 * 0.5 * scale, 276 * 0.5 * scale]}
            position={[0, size.height * 0.5 - 276 * 0.25 * scale - 16, 0]}
          >
            <meshBasicMaterial
              map={header}
              transparent={true}
              toneMapped={false}
            />
          </Plane>
        </group>
      ) : (
        <Plane
          args={[528 * 0.5 * scale, 125 * 0.5 * scale]}
          position={[0, size.height * 0.5 - 125 * 0.5 * scale + 16, 0]}
        >
          <meshBasicMaterial
            map={headerLandscape}
            transparent={true}
            toneMapped={false}
          />
        </Plane>
      )}

      {/* MP QR */}
      <Plane
        args={[144 * 0.5 * scale, 144 * 0.5 * scale]}
        position={[
          size.width * -0.5 + 144 * 0.25 * scale + 16,
          size.height * -0.5 + 144 * 0.25 * scale + 16,
          0,
        ]}
      >
        <meshBasicMaterial
          map={mpQr}
          transparent={true}
          toneMapped={false}
        />
      </Plane>

      {/* ROTJ40 */}
      <Plane
        args={[132 * 0.5 * scale, 154 * 0.5 * scale]}
        position={[
          size.width * 0.5 - 132 * 0.25 * scale - 16,
          size.height * -0.5 + 154 * 0.25 * scale + 16,
          0,
        ]}
      >
        <meshBasicMaterial
          map={rotj40}
          transparent={true}
          toneMapped={false}
        />
      </Plane>
    </Hud>
  );
};
