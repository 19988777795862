import { useRef, useEffect, useMemo } from 'react';
import { useThree, useFrame } from '@react-three/fiber';

import { AnimationMixer } from 'three';

import { getUrlFloat } from '@/helpers/UrlParam';

export function useAnimations(ref, animations, speed = 1, enabled = true) {
  const { invalidate } = useThree();
  let _speed = getUrlFloat('speed', speed);

  let mixer = useRef(null);
  let action = useRef(null);
  // let mixer = useMemo(() => {
  //   if (!ref.current) return;
  //   return new AnimationMixer(ref.current);
  // }, [ref, ref.current]);

  // let action = useMemo(() => {
  //   if (!animations?.[0]) return;
  //   if (!mixer) return;
  //   return mixer.clipAction(animations[0]);
  // }, [mixer, animations]);

  useEffect(() => {
    mixer.current = new AnimationMixer(ref.current);
    action.current = mixer.current.clipAction(animations[0]);

    action.current.play();

    return () => {
      action.current.stop();
    };
  }, [ref, animations]);

  useFrame((state, delta) => {
    if (!mixer.current) return;
    // if (!enabled) return;
    mixer.current.update(delta * _speed);
    // invalidate();
  });

  useEffect(() => {
    if (!enabled) {
      action.current.stop();
    } else {
      action.current.play();
    }
  }, [enabled]);

  return {
    mixer,
    action,
  };
}
