import {
  EffectComposer,
  BlendFunction,
  RenderPass,
  EffectPass,
  CopyPass,
  BloomEffect,
} from 'postprocessing';
import { HalfFloatType } from 'three';
// import { MotionBlurEffect, VelocityDepthNormalPass } from 'realism-effects';
// import { SSREffect } from 'screen-space-reflections';
// import { SSRDebugGUI } from './SSRDebugGUI';

export const useComposer = (gl, scene, camera, renderTarget) => {
  // const [composer, setComposer] = useState()

  const composer = useMemo(() => {
    if (!gl) return;
    if (!scene) return;
    if (!camera) return;

    // COMPOSER
    const composer = new EffectComposer(gl, {
      autoRenderToScreen: false,
      stencilBuffer: true,
      multisampling: 2,
      frameBufferType: HalfFloatType,
    });
    composer.autoRenderToScreen = false;

    // RENDER PASS
    composer.addPass(new RenderPass(scene, camera));

    // VELOCITY DEPTH NORMAL
    // const velocityDepthNormalPass = new VelocityDepthNormalPass(scene, camera);
    // composer.addPass(velocityDepthNormalPass);

    // const ssrOptions = {
    //   intensity: 1,
    //   exponent: 1,
    //   distance: 10,
    //   fade: 0,
    //   roughnessFade: 1,
    //   thickness: 10,
    //   ior: 1.45,
    //   maxRoughness: 1,
    //   maxDepthDifference: 10,
    //   blend: 0.9,
    //   correction: 1,
    //   correctionRadius: 1,
    //   blur: 0.5,
    //   blurKernel: 1,
    //   blurSharpness: 10,
    //   jitter: 0,
    //   jitterRoughness: 0,
    //   steps: 20,
    //   refineSteps: 5,
    //   missedRays: true,
    //   useNormalMap: true,
    //   useRoughnessMap: true,
    //   resolutionScale: 1,
    //   velocityResolutionScale: 1,
    // };
    // const ssrEffect = new SSREffect(scene, camera, ssrOptions);
    // composer.addPass(new EffectPass(camera, ssrEffect));
    // const gui = new SSRDebugGUI(ssrEffect, ssrOptions);

    // BLOOM
    const bloomEffect = new BloomEffect({
      luminanceThreshold: 0.6,
      luminanceSmoothing: 0.5,
      intensity: 1,
      blendFunction: BlendFunction.ADD,
    });

    // MOTION BLUR
    // const motionBlurEffect = new MotionBlurEffect(velocityDepthNormalPass, {
    //   intensity: 1,
    //   jitter: 0,
    //   samples: 16,
    // });

    composer.addPass(new EffectPass(camera, bloomEffect));

    // OUTPUT
    composer.addPass(new CopyPass(renderTarget));

    return composer;
  }, [gl, scene, camera, renderTarget]);

  return composer;
};
