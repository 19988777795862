import { Popup } from '@/components/ui/_general/Popup';
import './index.sass';

export const PopupBrightness = (props) => {
  return (
    <Popup
      className="PopupBrightness"
      onClose={props.onClose}
    >
      <h3>该体验最好在良好照明的室外</h3>
      <img
        className="icon"
        src="assets/images/ui/ar/bright.svg"
      />
      <button
        className="btn-primary"
        onClick={props.onClose}
      >
        知道了
      </button>
    </Popup>
  );
};
