import './index.sass';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useProgress } from '@react-three/drei';
import * as paths from '@/config/paths';
import ReactAnimatedEllipsis from 'react-animated-ellipsis';

export const TheLoading = (props) => {
  const p = useProgress();
  const progress = (p.progress || 0) / 100;
  console.log('progress', p);

  // let copy = '加载中';
  // let copy = '扫描成功，正在加载模型'
  let copy = props.copy || '加载中';

  return (
    <div className="TheLoading">
      <img
        className="lego"
        src={paths.imgUiGeneralLego}
      />
      <div className="label">
        {copy}
        <ReactAnimatedEllipsis />
      </div>
      <div className="bar">
        <motion.div
          className="inner"
          animate={{ scaleX: progress }}
        />
      </div>
    </div>
  );
};
