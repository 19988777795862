import { useState, useRef } from 'react';
import { MathUtils, DoubleSide } from 'three';
import {
  OrbitControls,
  Environment,
  PerspectiveCamera,
  useMask,
  Stars,
  PivotControls,
  Image,
} from '@react-three/drei';

import { EffectComposer, Bloom, Vignette } from '@react-three/postprocessing';
import { useControls } from 'leva';
import Env from '@/helpers/Env';

import { Reflector } from '@/components/ar/Reflector';

import { XwingModel } from './XwingModel-primitive';
import { XwingFrame } from './XwingFrame';
import { useLightPos } from '@/components/ar/utils/useLightPos.jsx';
import * as paths from '@/config/paths';

const presets = {
  custom: {
    main: [1.5, 1, 0.5],
    fill: [-2, -0.5, -2],
  },
  rembrandt: {
    main: [1, 2, 1],
    fill: [-2, -0.5, -2],
  },
  portrait: {
    main: [-1, 2, 0.5],
    fill: [-1, 0.5, -1.5],
  },
  upfront: {
    main: [0, 2, 1],
    fill: [-1, 0.5, -1.5],
  },
  soft: {
    main: [-2, 4, 4],
    fill: [-1, 0.5, -1.5],
  },
};

export function XwingScene() {
  // *******************************************************
  // SPOT LIGHT
  // *******************************************************
  const intensity = 2;
  const config = presets.custom;
  const radius = 3.8;
  const shadows = 'contact';
  const shadowBias = -0.001;
  const normalBias = 0;
  const shadowSize = 2048;
  const shadowOffset = 0;

  const ref = useRef(null);
  // const refSpotLight = useLightPos(ref, radius, config.main);
  // const refPointLight = useLightPos(ref, radius, [-0.1, 0.175, -1]);

  return (
    <group
      position={[0, -0.5, 0]}
      ref={ref}
    >
      <mesh rotation={[Math.PI * 0.5, 0, 0]}>
        <sphereGeometry args={[100, 32, 32, 0, Math.PI * 2, Math.PI * 0.5]} />
        <meshBasicMaterial
          color={'#000000'}
          side={DoubleSide}
        />
      </mesh>
      <Image
        url={paths.imgArXwingXwingBg}
        position={[0, -2, -50]}
        scale={[40, 40, 40]}
        transparent={true}
        opacity={0.3}
      />
      <group
        position={[0, 0, 0]}
        rotation={[
          MathUtils.degToRad(0),
          MathUtils.degToRad(-90 + 30),
          MathUtils.degToRad(0),
        ]}
      >
        <group
          position={[0.2, 0.51, 0]}
          rotation={[
            MathUtils.degToRad(-5),
            MathUtils.degToRad(0),
            MathUtils.degToRad(-20),
          ]}
          scale={[1.3, 1.3, 1.3]}
        >
          <XwingModel />
        </group>
      </group>
      <XwingFrame />

      <Environment preset={'city'} />

      <spotLight
        penumbra={1}
        intensity={intensity}
        castShadow={true}
        shadow-bias={shadowBias}
        shadow-normalBias={normalBias}
        shadow-mapSize={shadowSize}
        position={[
          config.main[0] * radius,
          config.main[1] * radius,
          config.main[2] * radius,
        ]}
      />
      {/* <pointLight
        intensity={intensity / 3}
        position={[
          -0.1 * radius, 
          0.175 * radius, 
          -1 * radius
          config.fill[0] * radius,
          config.fill[1] * radius,
          config.fill[2] * radius,
        ]}
      /> */}
    </group>
  );
}
