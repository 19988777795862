import * as paths from '@/config/paths';
import { createContext } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TextureLoader } from 'three/src/loaders/TextureLoader';

export const AssetsContext = createContext(null);

export const AssetsProvider = function (props) {
  const models = {
    box_speeder: useGLTF(paths.box_speeder),
    box_throne: useGLTF(paths.box_throne),
    box_xwing: useGLTF(paths.box_xwing),
    ground_speeder: useGLTF(paths.ground_speeder),
    ground_throne: useGLTF(paths.ground_throne),
    ground_xwing: useGLTF(paths.ground_xwing),
  };

  const [
    frame_speeder,
    frame_throne,
    frame_xwing,
    throne_texture,
    xwing_texture,
  ] = useLoader(TextureLoader, [
    paths.frame_speeder,
    paths.frame_throne,
    paths.frame_xwing,
    paths.throne_texture,
    paths.xwing_texture,
  ]);

  const cache = {
    models,
    textures: {
      frame_speeder,
      frame_throne,
      frame_xwing,
      throne_texture,
      xwing_texture,
    },
  };

  return (
    <AssetsContext.Provider value={cache}>
      {props.children}
    </AssetsContext.Provider>
  );
};
