import { Popup } from '@/components/ui/_general/Popup';
import './index.sass';

export const XR8_Failed = (props) => {
  const reloadPage = function () {
    console.log('reloadPage');
    window.location.reload();
  };
  return (
    <Popup className="popup-failed">
      <h3>此体验需要访问摄像头和运动传感器</h3>
      <h4>请刷新页面并重新授权</h4>
      {/* <img
        className="icon"
        src="assets/images/ui/_general/failed-camera.svg"
      /> */}
      <button
        className="btn-primary"
        onClick={reloadPage}
      >
        刷新
      </button>
    </Popup>
  );
};
