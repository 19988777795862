/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useRef, useEffect, useLayoutEffect, useMemo, useContext } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { PositionalAudio } from '@react-three/drei';
import { AssetsContext } from '@/components/ar/Assets';
import { useAnimations } from '@/components/ar/utils/useAnimations';
import { useAudioLoop } from '@/components/ar/utils/useAudioLoop';
import { LoopFlash } from '@/components/ar/utils/LoopFlash';
import * as colors from '@/config/colors';
import gsap from 'gsap';

import {
  useGLTF,
  useMask,
  PerspectiveCamera,
  useTexture,
  MeshTransmissionMaterial,
} from '@react-three/drei';
import {
  Plane,
  Vector3,
  AnimationMixer,
  MeshBasicMaterial,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
  Color,
} from 'three';

import * as paths from '@/config/paths';

const path = paths.ground_xwing;

export function XwingGroundModel(props) {
  const group = useRef();
  const { scene, nodes, materials, animations } = useGLTF(path);
  // const assetContext = useContext(AssetsContext);
  // const { scene, nodes, materials, animations } = assetContext.models.ground_xwing;

  const { mixer, action } = useAnimations(group, animations, 1, props.enabled);
  const refAudio = useRef(null);
  useAudioLoop(refAudio, mixer, props.enabled);

  // *******************************************************
  // WINDSHIELD
  // *******************************************************
  const texture = useTexture(paths.xwing_texture);
  // const texture = assetContext.textures.xwing_texture;
  const material = useMemo(() => {
    return new MeshPhysicalMaterial({
      map: texture,
      ior: 1,
      transmission: 0.95,
      thickness: 0.01,
      roughness: 0,
      metalness: 0.25,
      transparent: true,
    });
  }, [texture]);

  const materialLaserGreen = useMemo(() => {
    return new MeshStandardMaterial({
      color: new Color(colors.lightsaberGreen.color),
      emissive: new Color(colors.lightsaberGreen.emissive),
      emissiveIntensity: colors.lightsaberGreen.emissiveIntensity,
      toneMapped: false,
    });
  }, []);

  const materialLaserRed = useMemo(() => {
    return new MeshStandardMaterial({
      color: new Color(colors.lightsaberRed.color),
      emissive: new Color(colors.lightsaberRed.emissive),
      emissiveIntensity: colors.lightsaberRed.emissiveIntensity,
      toneMapped: false,
    });
  }, []);

  const materialEngine = useMemo(() => {
    return new MeshStandardMaterial({
      color: new Color(0x955793),
      emissive: new Color(0x955793),
      emissiveIntensity: 1,
      toneMapped: false,
    });
  }, []);

  useEffect(() => {
    const onLoop = () => {
      if (!materialEngine) return;
      let tl = new gsap.timeline();
      tl.set(materialEngine, { emissiveIntensity: 1 });
      tl.to(
        materialEngine,
        { emissiveIntensity: 10, duration: (182 - 171) / 24 },
        `+=${171 / 24}`
      );
      tl.to(materialEngine, { emissiveIntensity: 1 }, `+=6.5`);
    };
    onLoop();

    if (mixer.current) {
      mixer.current.addEventListener('loop', onLoop);
    }

    return () => {
      if (mixer.current) {
        mixer.current.removeEventListener('loop', onLoop);
      }
    };
  }, [mixer.current, materialEngine]);

  useEffect(() => {
    texture.flipY = false;
    texture.needsUpdate = true;
  }, [texture]);

  useEffect(() => {
    scene.traverse((obj) => {
      obj.castShadow = true;
      obj.receiveShadow = true;
      if (obj.material) {
        // obj.material.roughness = 0;
        // obj.material.metalness = 0;
        obj.material.envMapIntensity = 1;
      }

      console.log(obj.name);

      switch (obj.name) {
        case 'engine':
        case 'engine01':
        case 'engin02':
        case 'engine03':
          obj.material = materialEngine;
          break;

        case 'Body859_13':
        case 'Body_7':
          obj.material = material;
          break;

        case 'laser016':
        case 'laser017':
        case 'laser018':
        case 'laser019':
        case 'laser024':
        case 'laser025':
        case 'laser026':
        case 'laser027':
          obj.material = materialLaserRed;
          obj.material.envMapIntensity = 0;
          break;
        case 'laser004':
        case 'laser005':
        case 'laser006':
        case 'laser007':
        case 'laser020':
        case 'laser021':
        case 'laser022':
        case 'laser023':
          obj.material = materialLaserGreen;
          obj.material.envMapIntensity = 0;
          break;
      }
    });
  }, [material, materialLaserGreen, materialLaserRed, scene]);

  return (
    <group>
      <PositionalAudio
        ref={refAudio}
        url={paths.audio_ground_xwing}
        distance={10000}
      />
      <LoopFlash mixer={mixer} />
      <primitive
        object={scene}
        ref={group}
      />
    </group>
  );
}
