import './ArScene.sass';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Suspense, useEffect, useRef, useState } from 'react';
import { WebGLRenderer, Scene, MathUtils } from 'three';
import { Canvas, useThree } from '@react-three/fiber';

import {
  PerspectiveCamera,
  Stats,
  OrbitControls,
  Plane,
  PresentationControls,
  Environment,
  AdaptiveDpr,
  RenderTexture,
  useFBO,
  Preload,
  Hud,
  Image,
  OrthographicCamera,
  Html,
} from '@react-three/drei';

import { PosterOverlay } from './PosterOverlay';
import { EffectComposer, Bloom, SSR } from '@react-three/postprocessing';

import { Perf } from 'r3f-perf';

import { AssetsProvider } from '@/components/ar/Assets';
import { TheLoading } from '@/components/ui/_general/TheLoading';
import { ArUi } from '@/components/ui/ar/ArUi';
import { Poster } from '@/components/ui/ar/Poster';

import { XR8 } from '@/components/ar/xr8/XR8';
import { XR8_Canvas } from '@/components/ar/xr8/XR8_Canvas';
import { XR8_CameraFeed } from '@/components/ar/xr8/XR8_CameraFeed';
import { XR8_Camera } from '@/components/ar/xr8/XR8_Camera';
import { XR8_ImageTarget } from '@/components/ar/xr8/XR8_ImageTarget';
import { XR8_PlaceGround } from '@/components/ar/xr8/XR8_PlaceGround';
import { XR8_AbsoluteScale } from '@/components/ar/xr8/XR8_AbsoluteScale';
import { XR8_Layer } from '@/components/ar/xr8/XR8_Layer';
import { XR8_LayerCamera } from '@/components/ar/xr8/XR8_LayerCamera';
import { XR8_LayerCameraDesktop } from '@/components/ar/xr8/XR8_LayerCameraDesktop';
import { XR8_Environment } from '@/components/ar/xr8/XR8_Environment';
import { Demo } from '@/components/ar/xr8/Demo';
import { DemoBox } from '@/components/ar/xr8/DemoBox';

import { ThroneScene } from '@/components/ar/scenes/throne/ThroneScene';
import { SpeederScene } from '@/components/ar/scenes/speeder/SpeederScene';
import { XwingScene } from '@/components/ar/scenes/xwing/XwingScene';

import { ThroneGroundScene } from '@/components/ar/scenes/throne-ground/ThroneGroundScene';
import { SpeederGroundScene } from '@/components/ar/scenes/speeder-ground/SpeederGroundScene';
import { XwingGroundScene } from '@/components/ar/scenes/xwing-ground/XwingGroundScene';

import Env from '@/helpers/Env';
import { fov, cameraZ } from '@/components/ar/utils/helpers';
import { useAppStore } from '@/stores/app';
import { getUrlBoolean } from '@/helpers/UrlParam';
import { SelectMode } from '../ui/home/SelectMode';

const debug = getUrlBoolean('debug', false);

console.log('ArScene');

export function SceneMobile(props) {
  const mode = useAppStore((state) => state.mode);
  const scene = useAppStore((state) => state.scene);
  const setMode = useAppStore((state) => state.setMode);
  const setScene = useAppStore((state) => state.setScene);

  const [foundScale, setFoundScale] = useState(false);
  const [placed, setPlaced] = useState(false);

  const [showLuke, setShowLuke] = useState(true);
  const [size, setSize] = useState('m');
  const [scale, setScale] = useState(1);

  const refCanvas = useRef(null);
  const [posterUrl, setPosterUrl] = useState(null);
  const onCaptureImage = () => {
    let url = refCanvas.current.toDataURL('image/jpeg', 0.9);
    setPosterUrl(url);
  };

  const [targetFound, setTargetFound] = useState(null);

  console.log('ArScene.placed', placed);

  const onBack = function () {
    console.log('onBack');
    setFoundScale(false);
    setPlaced(false);
    window.XR8.XrController.recenter();
    setShowLuke(true);
    setSize('m');
    setScale(1);
    // setMode(null);
    setScene(null);
    props.onBack?.();
  };

  return (
    <motion.div
      className="ArScene"
      initial={{ x: '100%' }}
      animate={{
        x: props.show ? '0%' : '100%',
        transition: { ease: 'easeOut', duration: 0.4 },
      }}
    >
      <canvas
        id="cameraFeed"
        ref={refCanvas}
      />
      <Suspense fallback={<TheLoading />}>
        <XR8 canvas={refCanvas}>
          <XR8_Canvas
            dpr={[0.5, 2]}
            shadows="soft"
            performance={{ min: 0.5 }}
          >
            <AdaptiveDpr pixelated />

            {debug && (
              <>
                <Perf />
                <Stats className={'stats'} />
              </>
            )}

            <PerspectiveCamera
              name="SceneCamera"
              fov={fov}
              makeDefault
              position={[0, 0, cameraZ]}
            />

            <XR8_Layer>
              <XR8_LayerCamera position={[0, 0, 0]} />

              {props.show && mode == 'box' && (
                <Suspense
                  fallback={
                    <Html>
                      <TheLoading copy={'扫描成功，正在加载模型'} />
                    </Html>
                  }
                >
                  <XR8_ImageTarget setTargetFound={setTargetFound}>
                    {targetFound == 'throne' && <ThroneScene />}
                    {targetFound == 'speeder' && <SpeederScene />}
                    {targetFound == 'xwing' && <XwingScene />}
                  </XR8_ImageTarget>
                </Suspense>
              )}

              {props.show && mode == 'ground' && (
                <>
                  <XR8_AbsoluteScale
                    onScaleFound={(val) => setFoundScale(true)}
                    onScaleLost={(val) => setFoundScale(false)}
                  />
                  <XR8_PlaceGround
                    onPlaced={() => setPlaced(true)}
                    onUnplaced={() => setPlaced(false)}
                    foundScale={foundScale}
                    placed={placed}
                  >
                    {/* <XR8_Environment /> */}
                    <Environment preset="city" />

                    <group scale={scale}>
                      {scene == 'speeder' && (
                        <SpeederGroundScene enabled={placed} />
                      )}
                      {scene == 'throne' && (
                        <ThroneGroundScene
                          showLuke={showLuke}
                          enabled={placed}
                        />
                      )}
                      {scene == 'xwing' && (
                        <XwingGroundScene
                          size={size}
                          enabled={placed}
                        />
                      )}
                    </group>
                  </XR8_PlaceGround>
                </>
              )}
            </XR8_Layer>
            <PosterOverlay />
            {/* <Preload all /> */}
          </XR8_Canvas>
        </XR8>
      </Suspense>

      <ArUi
        mode={mode}
        scene={scene}
        onBack={onBack}
        targetFound={targetFound}
        foundScale={foundScale}
        placed={placed}
        showLuke={showLuke}
        size={size}
        scale={scale}
        onToggleLuke={() => {
          setShowLuke(!showLuke);
        }}
        onSetSize={(size) => {
          setSize(size);
        }}
        onSetScale={(scale) => {
          setScale(scale);
        }}
        onCaptureImage={onCaptureImage}
      />

      {posterUrl && (
        <Poster
          url={posterUrl}
          onBack={() => setPosterUrl(null)}
        />
      )}
    </motion.div>
  );
}

export function SceneDesktop(props) {
  const mode = useAppStore((state) => state.mode);
  const scene = useAppStore((state) => state.scene);
  const setMode = useAppStore((state) => state.setMode);
  const setScene = useAppStore((state) => state.setScene);

  const [showLuke, setShowLuke] = useState(true);
  const [size, setSize] = useState('m');
  const [scale, setScale] = useState(1);

  const onBack = function () {
    console.log('ArScene.onBack');
    setShowLuke(true);
    setSize('m');
    // setMode(null);
    // setScene(null);
    props.onBack?.();
  };

  return (
    <motion.div
      className={`ArScene ${props.show ? 'show' : 'hide'} ${`mode-${mode}`}`}
      initial={{ x: '100%' }}
      animate={{ x: '0%', transition: { ease: 'linear', duration: 0.1 } }}
    >
      <Canvas
        dpr={[0.5, 2]}
        shadows="soft"
        performance={{ min: 0.5 }}
      >
        <PerspectiveCamera
          fov={fov}
          makeDefault
          position={[
            0,
            mode == 'ground' ? 1.3 : 0,
            cameraZ + (mode == 'ground' ? 6 : 0),
          ]}
        />

        <XR8_Layer>
          <XR8_LayerCameraDesktop />
          {mode == 'box' && (
            <>
              {/* <OrbitControls
                minAzimuthAngle={MathUtils.degToRad(-80)}
                maxAzimuthAngle={MathUtils.degToRad(80)}
                minPolarAngle={MathUtils.degToRad(15)}
                maxPolarAngle={MathUtils.degToRad(165)}
                key={scene}
              /> */}
              <PresentationControls>
                {scene == 'throne' && <ThroneScene />}
                {scene == 'speeder' && <SpeederScene />}
                {scene == 'xwing' && <XwingScene />}
              </PresentationControls>
            </>
          )}

          {mode == 'ground' && (
            <group scale={scale}>
              <Environment preset={'city'} />
              {scene == 'throne' && (
                <group>
                  <OrbitControls />
                  <group rotation={[0, MathUtils.degToRad(-20), 0]}>
                    <ThroneGroundScene showLuke={showLuke} />
                  </group>
                  {/* </OrbitControls> */}
                </group>
              )}
              {scene == 'speeder' && (
                <PresentationControls>
                  <group rotation={[0, MathUtils.degToRad(45), 0]}>
                    <SpeederGroundScene />
                  </group>
                </PresentationControls>
              )}
              {scene == 'xwing' && (
                <PresentationControls>
                  <group rotation={[0, MathUtils.degToRad(0), 0]}>
                    <XwingGroundScene size={size} />
                  </group>
                </PresentationControls>
              )}
            </group>
          )}
        </XR8_Layer>

        {/* <axesHelper /> */}

        {/* <EffectComposer
          stencilBuffer
          disableNormalPass={true}
          renderPriority={2}
          autoClear={false}
        >
          <SSR />
          <Bloom
            luminanceThreshold={0.6}
            luminanceSmoothing={0.5}
            intensity={1}
          />
        </EffectComposer> */}

        {debug && (
          <>
            <Perf />
            <Stats className={'stats'} />
          </>
        )}

        <PosterOverlay />
      </Canvas>

      <ArUi
        mode={mode}
        scene={scene}
        onBack={onBack}
        size={size}
        scale={scale}
        showLuke={showLuke}
        onToggleLuke={() => {
          setShowLuke(!showLuke);
        }}
        onSetSize={(size) => {
          setSize(size);
        }}
        onSetScale={(scale) => {
          setScale(scale);
        }}
      />
    </motion.div>
  );
}

export function ArScene(props) {
  return (
    <>{Env.mobile ? <SceneMobile {...props} /> : <SceneDesktop {...props} />}</>
    // <SceneDesktop {...props} />
  );
}
