import './index.sass';

import { TermsPopup } from '../TermsPopup';
import { useState, useEffect } from 'react';
import { motion, useAnimate } from 'framer-motion';
import gsap from 'gsap';
import * as paths from '@/config/paths';
export const Bg = (props) => {
  const [showTerms, setShowTerms] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(true);

  const [scope, animate] = useAnimate();

  const refDeathStar = useRef(null);
  const isInner = props.page != 'landing';

  useEffect(() => {
    if (isInner) {
      const animateInner = async () => {
        let tl = new gsap.timeline();
        tl.to(refDeathStar.current, {
          y: 30,
          duration: 0.6,
          ease: 'power1.InOut',
        });
        tl.to(
          refDeathStar.current,
          { scale: 1.4, duration: 0.6, ease: 'power2.inOut' },
          '-=0.4'
        );
        await tl.then();
      };
      animateInner();
    } else {
      const animateOuter = async () => {
        let tl = new gsap.timeline();
        tl.set(refDeathStar.current, { y: 0 });
        tl.set(refDeathStar.current, { scale: 1 });
        await tl.then();
      };
      animateOuter();
    }
  }, [isInner]);

  return (
    <div
      className={`Bg page`}
      ref={scope}
    >
      <img
        className="stars"
        src={paths.imgUiHomeBgStars}
      />
      <img
        ref={refDeathStar}
        className="deathstar"
        src={paths.imgUiHomeBgDeathstar}
      />
      <img
        className="tiefighters"
        src={paths.imgUiHomeBgTiefighters}
      />
      {/* <img
        className="frame"
        src={paths.imgUiHomeBgFrame}
      /> */}

      <motion.img
        className="logo"
        src="assets/images/ui/_general/lego-starwars.svg"
        animate={{ height: isInner ? '4.2rem' : '7.5vh' }}
      />
    </div>
  );
};
