// *****************************************************************
// Use this component to merge the r3f canvas with 8th wall's canvas
// *****************************************************************

import { useEffect, useMemo } from 'react';
import { Canvas } from '@react-three/fiber';
import {
  WebGLRenderer,
  sRGBEncoding,
  ACESFilmicToneMapping,
  PCFSoftShadowMap,
} from 'three';

export function XR8_Canvas(props) {
  const [started, setStarted] = useState(false);
  const [_renderer, setRenderer] = useState(null);

  const module = useMemo(() => {
    const onStart = ({ canvas, canvasWidth, canvasHeight, GLctx }) => {
      const renderer = new WebGLRenderer({
        canvas,
        context: GLctx,
        alpha: true,
        antialias: false,
        powerPreference: 'high-performance',
        preserveDrawingBuffer: true,
      });
      renderer.outputEncoding = sRGBEncoding;
      renderer.toneMapping = ACESFilmicToneMapping;
      renderer.shadows = PCFSoftShadowMap;

      renderer.autoClear = false;

      window.renderer = renderer;

      setRenderer(renderer);

      setStarted(true);
    };

    return {
      name: 'XR8_Canvas',
      onStart,
    };
  }, []);

  useEffect(() => {
    XR8.addCameraPipelineModule(module);

    return () => {
      XR8.removeCameraPipelineModule(module.name);
    };
  }, [module]);

  const getRenderer = function (canvas) {
    return _renderer;
  };

  return (
    <>
      {started && _renderer && (
        <Canvas
          {...props}
          gl={getRenderer}
        >
          {props.children}
        </Canvas>
      )}
    </>
  );
}
