import { useTexture, Plane, Mask } from '@react-three/drei';
import { HiderWalls } from '@/components/ar/utils/HiderWalls';
import * as paths from '@/config/paths';
import { useContext } from 'react';
import { AssetsContext } from '@/components/ar/Assets';
export function ThroneFrame(props) {
  // const assetContext = useContext(AssetsContext);
  // const texture = assetContext.textures.frame_throne;
  const texture = useTexture(paths.frame_throne);

  const width = 2048;
  const height = 1383;
  const ratio = width / height;

  return (
    <group position={[0, 0.5, 0]}>
      <Plane args={[ratio, 1]}>
        <meshBasicMaterial
          map={texture}
          transparent={true}
          opacity={1}
          toneMapped={false}
        />
      </Plane>

      <HiderWalls ratio={ratio} />
    </group>
  );
}
