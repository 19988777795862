import './index.sass';

export const Cta = (props) => {
  return (
    <div className="Cta">
      <div className="label">{props.text}</div>
      <div className="spacer">{props.children}</div>
    </div>
  );
};
