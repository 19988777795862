import './index.sass';
import { motion } from 'framer-motion';

export const Scanning = (props) => {
  return (
    <div
      className="Scanning"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="top" />
      <div className="center">
        <div className="left" />
        <div className="box">
          <img
            className="borders"
            src="assets/images/ui/ar/scanning.svg"
          />
          <div className="bar-area">
            <div className="bar" />
          </div>

          <div className="label">扫描乐高包装盒</div>
        </div>
        <div className="right" />
      </div>
      <div className="bottom" />
    </div>
  );
};
