import './index.sass';
import { motion } from 'framer-motion';
import * as paths from '@/config/paths';
import { BackButtons } from '@/components/ui/_general/BackButtons';

export const SelectGroundScene = (props) => {
  const list = {
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { ease: 'easeOut', duration: 0.3 },
    },
    exit: {
      opacity: 0,
      y: 50,
      transition: { ease: 'easeOut', duration: 0.3 },
    },
  };

  return (
    <motion.div
      className="SelectGroundScene page"
      initial="hidden"
      animate="visible"
      variants={list}
    >
      {/* <button
        className="btn-back"
        onClick={() => props.onBack?.()}
      >
        <img src="assets/images/ui/_general/back.svg" />
      </button> */}
      <BackButtons onBack={props.onBack} />
      <motion.img
        className="title"
        src="assets/images/ui/home/select-ground-scene/title.svg"
        variants={item}
      />
      <div className="buttons">
        <motion.button
          onClick={() => props.onSceneClick?.('speeder')}
          variants={item}
        >
          <img
            className="scene scene-speeder"
            src={paths.imgUiHomeSelectGroundSceneSpeeder}
          />
          <img
            className="inner scene-speeder-inner"
            src={paths.imgUiHomeSelectGroundSceneSpeederInner}
          />
        </motion.button>
        <motion.button
          onClick={() => props.onSceneClick?.('xwing')}
          variants={item}
        >
          <img
            className="scene scene-xwing"
            src={paths.imgUiHomeSelectGroundSceneXwing}
          />
          <img
            className="inner scene-xwing-inner"
            src={paths.imgUiHomeSelectGroundSceneXwingInner}
          />
        </motion.button>
        <motion.button
          onClick={() => props.onSceneClick?.('throne')}
          variants={item}
        >
          <img
            className="scene scene-throne"
            src={paths.imgUiHomeSelectGroundSceneThrone}
          />
          <img
            className="inner scene-throne-inner"
            src={paths.imgUiHomeSelectGroundSceneThroneInner}
          />
        </motion.button>
      </div>
    </motion.div>
  );
};
