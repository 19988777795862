import './index.sass';
import { motion } from 'framer-motion';
import * as paths from '@/config/paths';
export const SelectMode = (props) => {
  const list = {
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { ease: 'easeOut', duration: 0.3 },
    },
    exit: {
      opacity: 0,
      y: 50,
      transition: { ease: 'easeOut', duration: 0.3 },
    },
  };

  return (
    <motion.div
      className="SelectMode page"
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={list}
    >
      {/* <button
        className="btn-back"
        onClick={() => props.onBack?.()}
      >
        <img src="assets/images/ui/_general/back.svg" />
      </button> */}
      <motion.img
        className="title"
        src="assets/images/ui/home/select-mode/mode-title.svg"
        variants={item}
      />
      <div className="buttons">
        <motion.button
          onClick={() => props.onModeClick?.('box')}
          variants={item}
        >
          <img
            className="mode mode-box"
            src={paths.imgUiHomeSelectModeModeBox}
          />
        </motion.button>
        <motion.button
          onClick={() => props.onModeClick?.('ground')}
          variants={item}
        >
          <img
            className="mode mode-ground"
            src={paths.imgUiHomeSelectModeModeGround}
          />
        </motion.button>
      </div>
    </motion.div>
  );
};
