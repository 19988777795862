import './index.sass';
import { useState } from 'react';
import { Popup } from '@/components/ui/_general/Popup';
import { Cta } from '@/components/ui/_general/Cta';
import { Debugger } from '@/components/ui/_general/Debugger';
import { PopupBox } from '@/components/ui/ar/PopupBox';
import { PopupBrightness } from '@/components/ui/ar/PopupBrightness';
import { Scanning } from '@/components/ui/ar/Scanning';
import { motion, AnimatePresence } from 'framer-motion';
import Env from '@/helpers/Env';
import { getUrlBoolean } from '@/helpers/UrlParam';
import * as paths from '@/config/paths';
import { BackButtons } from '@/components/ui/_general/BackButtons';
import { ScaleSlider } from '@/components/ui/ar/ScaleSlider';

let seenPopupXwingSize = false;
let seenPopupBox = !Env.mobile;
let seenPopupBrightness = !Env.mobile;

const debug = getUrlBoolean('debug', false);

export const ArUi = (props) => {
  const [showPopupScreenRecord, setShowPopupScreenRecord] = useState(false);

  const showCameraButton =
    (props.mode == 'box' && props.targetFound) ||
    (props.foundScale && props.placed);

  // THRONE CONTROLS
  const showThroneControls = props.mode == 'ground' && props.scene == 'throne';
  const showSpeederControls =
    props.mode == 'ground' && props.scene == 'speeder';

  // XWING CONTROLS
  const showXwingControls = props.mode == 'ground' && props.scene == 'xwing';

  const [showPopupXwingSize, setShowPopupXwingSize] = useState(false);

  const onPopupXwingSizeClose = function () {
    setShowPopupXwingSize(false);
    seenPopupXwingSize = true;
  };

  const onSetSize = function (size) {
    props.onSetSize?.(size);
    if (size == 'l' && !seenPopupXwingSize) {
      setShowPopupXwingSize(true);
    }
  };

  // POPUP BOX INFO
  const [showPopupBox, setShowPopupBox] = useState(!seenPopupBox);
  const onPopupBoxClose = function () {
    setShowPopupBox(false);
    seenPopupBox = true;
  };

  // POPUP BOX INFO
  const [showPopupBrightness, setShowPopupBrightness] = useState(
    !seenPopupBrightness
  );
  const onPopupBrightnessClose = function () {
    setShowPopupBrightness(false);
    seenPopupBrightness = true;
  };

  return (
    <>
      <div className="ArUi page">
        {/* <button
          className="btn-back"
          onClick={() => props.onBack?.()}
        >
          <img src="assets/images/ui/_general/back.svg" />
        </button> */}
        <BackButtons onBack={() => props.onBack?.()} />
        <div className="top">
          <div className="left"></div>
          <div className="center">
            <img
              className="logo"
              src="assets/images/ui/_general/lego-starwars.svg"
            />
            <img
              className="lockup"
              src={paths.imgUiArLockup}
            />
            <img
              className="header-landscape"
              src={paths.imgUiArHeaderLandscape}
            />
          </div>
          <div className="right">
            <button
              className="btn-header btn-record"
              onClick={() => {
                setShowPopupScreenRecord(true);
              }}
            >
              <img src="assets/images/ui/ar/record.svg" />
            </button>

            {/* {props.mode == 'box' && (
              <button
                className="btn-box-info"
                onClick={() => setShowPopupBox(true)}
              >
                <img src="assets/images/ui/_general/info.svg" />
              </button>
            )} */}

            {showXwingControls && (
              <div className="size-buttons">
                <button
                  className={`btn-s ${props.size == 's' ? 'active' : ''}`}
                  onClick={() => onSetSize('s')}
                >
                  小
                </button>
                <button
                  className={`btn-m ${props.size == 'm' ? 'active' : ''}`}
                  onClick={() => onSetSize('m')}
                >
                  中
                </button>
                <button
                  className={`btn-l ${props.size == 'l' ? 'active' : ''}`}
                  onClick={() => onSetSize('l')}
                >
                  1:1
                </button>
              </div>
            )}

            {showThroneControls && (
              <>
                <div className="buttons">
                  <button
                    className="btn-header btn-hide"
                    onClick={() => {
                      props.onToggleLuke?.();
                    }}
                  >
                    {props.showLuke ? (
                      <img src="assets/images/ui/ar/hide.svg" />
                    ) : (
                      <img src="assets/images/ui/ar/show.svg" />
                    )}
                    <div className="label">
                      {props.showLuke ? '隐藏卢克' : '显示卢克'}
                    </div>
                  </button>
                </div>
                <ScaleSlider
                  scale={props.scale}
                  onChange={(evt) => {
                    props.onSetScale?.(parseFloat(evt.target.value));
                  }}
                />
              </>
            )}

            {showSpeederControls && (
              <ScaleSlider
                scale={props.scale}
                onChange={(evt) => {
                  props.onSetScale?.(parseFloat(evt.target.value));
                }}
              />
            )}
          </div>
        </div>
        <div className="flex" />

        <div className="bottom">
          <img
            className="mp"
            src={paths.imgUiArLegoMpQr}
          />
          {showCameraButton && (
            <button
              className="btn-capture"
              onClick={() => {
                props.onCaptureImage?.();
              }}
            >
              <div className="inner" />
            </button>
          )}
          <img
            className="rotj40"
            src={paths.imgUiArRotj40}
          />
        </div>

        {Env.mobile && props.mode == 'ground' && !props.foundScale && (
          <>
            <Cta
              text="前后移动手机"
              className="cta-coaching"
            >
              <img
                className="coaching-overlay"
                src="/assets/images/ui/ar/coaching-overlay-anim.svg"
              />
            </Cta>
          </>
        )}

        {Env.mobile &&
          props.mode == 'ground' &&
          props.foundScale &&
          !props.placed && (
            <>
              <Cta text="点击屏幕放置模型"></Cta>
            </>
          )}

        {debug && (
          <Debugger
            mode={props.mode}
            scene={props.scene}
            foundScale={props.foundScale}
            placed={props.placed}
            showLuke={props.showLuke}
          />
        )}
      </div>

      <AnimatePresence>
        {Env.mobile && showPopupXwingSize && (
          <Popup onClose={onPopupXwingSizeClose}>
            <h3>现在展示的是X翼星际战斗机的真实尺寸:</h3>
            <img src={paths.imgUiArSizeXwing} />
            <p>最佳观看距离5米</p>
            <button
              className="btn-primary"
              onClick={onPopupXwingSizeClose}
            >
              知道了
            </button>
          </Popup>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showPopupScreenRecord && (
          <Popup
            className="popup-record"
            onClose={() => setShowPopupScreenRecord(false)}
          >
            <h3>截屏或录屏分享给好友</h3>
            <img
              className="record"
              src="assets/images/ui/ar/screen-record.svg"
            />
            <button
              className="btn-primary"
              onClick={() => setShowPopupScreenRecord(false)}
            >
              知道了
            </button>
          </Popup>
        )}
      </AnimatePresence>

      {props.mode == 'box' && (
        // <AnimatePresence>
        //   {showPopupBox && (
        //     <PopupBox
        //       setScene={props.setScene}
        //       onClose={onPopupBoxClose}
        //     />
        //   )}
        //   {!showPopupBox && !props.targetFound && Env.mobile && <Scanning />}
        // </AnimatePresence>
        <>{!props.targetFound && Env.mobile && <Scanning />}</>
      )}

      {props.mode == 'ground' && (
        <AnimatePresence>
          {showPopupBrightness && (
            <PopupBrightness
              setScene={props.setScene}
              onClose={onPopupBrightnessClose}
            />
          )}
        </AnimatePresence>
      )}

      {debug && (
        <div className="scale-debug">
          <div className="scale-head">
            <div className="scale-title">Scale</div>
            <div className="scale-value">{props.scale}</div>
          </div>
          <input
            className="scale-input"
            type="range"
            value={props.scale}
            onChange={(evt) => {
              props.onSetScale?.(parseFloat(evt.target.value));
            }}
            min="0.1"
            max="2"
            step="0.1"
          />
        </div>
      )}
    </>
  );
};
