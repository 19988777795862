/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useRef, useEffect, useContext } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useGLTF, useMask, PositionalAudio, Trail } from '@react-three/drei';
import { Plane, Vector3, AnimationMixer } from 'three';
import { AssetsContext } from '@/components/ar/Assets';
import * as paths from '@/config/paths';
import { useAnimations } from '@/components/ar/utils/useAnimations';
import { useAudioLoop } from '@/components/ar/utils/useAudioLoop';
import * as colors from '@/config/colors';
import { LoopFlash } from '@/components/ar/utils/LoopFlash';

const path = paths.ground_throne;

export function ThroneGroundModel(props) {
  const group = useRef();
  const { scene, nodes, materials, animations } = useGLTF(path);
  // const assetContext = useContext(AssetsContext);
  // const { scene, nodes, materials, animations } = assetContext.models.ground_throne;

  const { mixer } = useAnimations(group, animations, 1.5, props.enabled);
  const refAudio = useRef(null);
  useAudioLoop(refAudio, mixer, props.enabled);

  // **************************************************
  // ENV MAP INTENSITY
  // **************************************************
  useEffect(() => {
    for (let id in materials) {
      // materials[id].roughness = 0;
      // materials[id].metalness = 0;
      materials[id].envMapIntensity = 1;
    }
  }, [materials]);

  const refLuke = useRef(null);
  // useEffect(() => {
  //   refLuke.current.visible = props.showLuke;
  // }, [props.showLuke]);

  return (
    <group
      ref={group}
      {...props}
    >
      <PositionalAudio
        ref={refAudio}
        url={paths.audio_ground_throne}
        distance={10000}
        loop={false}
      />
      <LoopFlash mixer={mixer} />
      <group name="Root_Scene">
        <group
          name="CatalougeFig_Rig2017_v02Mesh"
          position={[-3.4915, -1.7062, -2.1671]}
          scale={35}
        >
          <group
            name="CatalougeFig_Rig2017_v02Body"
            position={[0.0838, 0.0488, 0.0658]}
            rotation={[-Math.PI, 0.6606, -Math.PI]}
          >
            <group
              name="CatalougeFig_Rig2017_v02Grp_L_Leg"
              position={[0.004, 0.0112, -0.0002]}
            >
              <mesh
                name="m3817_V1"
                castShadow
                receiveShadow
                geometry={nodes.m3817_V1.geometry}
                material={materials.phong1}
                position={[-0.284, -0.0112, -0.0755]}
              />
            </group>
            <group
              name="CatalougeFig_Rig2017_v02Grp_R_Leg"
              position={[-0.004, 0.0112, -0.0001]}
              rotation={[-0.0011, -0.058, -0.0195]}
            >
              <mesh
                name="m3816_U1"
                castShadow
                receiveShadow
                geometry={nodes.m3816_U1.geometry}
                material={materials.phong1}
                position={[-0.276, -0.0112, -0.0755]}
              />
            </group>
            <group
              name="CatalougeFig_Rig2017_v02Hip"
              position={[-0.0041, 0.0158, -0.0008]}
              rotation={[-0.0844, -0.0609, 0.0228]}
            >
              <group
                name="CatalougeFig_Rig2017_v02LeftArm"
                position={[0.0103, 0.009, 0]}
                rotation={[0.8707, 0.134, 0.1121]}
              >
                <group
                  name="CatalougeFig_Rig2017_v02LeftHand"
                  position={[0.002, -0.0032, 0.0068]}
                  rotation={[0.0074, 0.0018, 0.1745]}
                >
                  <mesh
                    name="m3820_D2"
                    castShadow
                    receiveShadow
                    geometry={nodes.m3820_D2.geometry}
                    material={materials.phong1}
                    position={[-0.2788, 0.0779, -0.0828]}
                    rotation={[-0.0003, -0.0018, -Math.PI / 9]}
                  />
                </group>
                <mesh
                  name="m3819_N2"
                  castShadow
                  receiveShadow
                  geometry={nodes.m3819_N2.geometry}
                  material={materials.phong1}
                  position={[-0.2863, 0.0251, -0.0755]}
                  rotation={[-0.0003, 0, -0.1745]}
                />
              </group>
              <group
                name="CatalougeFig_Rig2017_v02RightArm"
                position={[-0.0023, 0.009, 0]}
                rotation={[0.424, -0.1103, -0.0349]}
              >
                <group
                  name="CatalougeFig_Rig2017_v02RightHand"
                  position={[-0.002, -0.0032, 0.0068]}
                  rotation={[-0.1038, 0.0279, -0.2934]}
                >
                  <mesh
                    name="m3820_D5"
                    castShadow
                    receiveShadow
                    geometry={nodes.m3820_D5.geometry}
                    material={materials.phong1}
                    position={[-0.2262, -0.1509, -0.0828]}
                    rotation={[0.0003, -0.0018, 0.5066]}
                  >
                    <mesh
                      name="m64567_G1"
                      castShadow
                      receiveShadow
                      geometry={nodes.m64567_G1.geometry}
                      material={materials.phong1}
                    >
                      <mesh
                        name="m30374_B3"
                        castShadow
                        geometry={nodes.m30374_B3.geometry}
                        material={materials.trans}
                      >
                        {/* RED */}
                        <meshStandardMaterial
                          color={colors.lightsaberRed.color}
                          emissive={colors.lightsaberRed.emissive}
                          emissiveIntensity={
                            colors.lightsaberRed.emissiveIntensity
                          }
                          toneMapped={false}
                        />
                      </mesh>
                    </mesh>
                  </mesh>
                </group>
                <mesh
                  name="m3818_L"
                  castShadow
                  receiveShadow
                  geometry={nodes.m3818_L.geometry}
                  material={materials.phong1}
                  position={[-0.2652, -0.0721, -0.0755]}
                  rotation={[0.0003, 0, 0.1745]}
                />
              </group>
              <group
                name="CatalougeFig_Rig2017_v02Head"
                position={[0.004, 0.0128, 0]}
                rotation={[0.1283, 0, 0]}
              >
                <mesh
                  name="m19916_C"
                  castShadow
                  receiveShadow
                  geometry={nodes.m19916_C.geometry}
                  material={materials.phong1}
                  position={[-0.28, -0.0288, -0.076]}
                  rotation={[0, -0.0018, 0]}
                />
              </group>
              <mesh
                name="m1010488_H"
                castShadow
                receiveShadow
                geometry={nodes.m1010488_H.geometry}
                material={materials.phong1}
                morphTargetDictionary={nodes.m1010488_H.morphTargetDictionary}
                morphTargetInfluences={nodes.m1010488_H.morphTargetInfluences}
                position={[-0.276, -0.016, -0.0755]}
              />
              <mesh
                name="m3814_T1"
                castShadow
                receiveShadow
                geometry={nodes.m3814_T1.geometry}
                material={materials.phong1}
                position={[-0.276, -0.016, -0.0755]}
              />
            </group>
          </group>
        </group>
        <group
          ref={refLuke}
          name="CatalougeFig_Rig2017_v03CatalogueFig_Rig"
          position={[-3.4915, -1.669, -2.1671]}
          scale={35}
          visible={props.showLuke}
        >
          <group name="CatalougeFig_Rig2017_v03Mesh">
            <group
              name="CatalougeFig_Rig2017_v03Body"
              position={[0.1263, 0.0477, 0.0564]}
              rotation={[0, -1.0912, 0]}
            >
              <group name="locator1">
                <group
                  name="CatalougeFig_Rig2017_v03Hip"
                  position={[-0.004, 0.0159, 0.0005]}
                  rotation={[0.1331, 0, 0]}
                >
                  <group
                    name="CatalougeFig_Rig2017_v03LeftArm"
                    position={[0.0103, 0.009, 0]}
                    rotation={[1.1764, 0.1614, 0.0668]}
                  >
                    <group
                      name="CatalougeFig_Rig2017_v03LeftHand"
                      position={[0.002, -0.0032, 0.0068]}
                      rotation={[0.0006, 0.0018, 0.1745]}
                    >
                      <mesh
                        name="m3820_D1"
                        castShadow
                        receiveShadow
                        geometry={nodes.m3820_D1.geometry}
                        material={materials.phong1}
                        position={[-0.2413, 0.0642, -0.0827]}
                        rotation={[-0.0003, -0.0018, -Math.PI / 9]}
                      />
                    </group>
                    <mesh
                      name="m3819_N1"
                      castShadow
                      receiveShadow
                      geometry={nodes.m3819_N1.geometry}
                      material={materials.phong1}
                      position={[-0.2469, 0.0182, -0.0755]}
                      rotation={[-0.0003, 0, -0.1745]}
                    />
                  </group>
                  <group
                    name="CatalougeFig_Rig2017_v03RightArm"
                    position={[-0.0023, 0.009, 0]}
                    rotation={[0.2329, -0.0407, -0.1698]}
                  >
                    <group
                      name="CatalougeFig_Rig2017_v03RightHand"
                      position={[-0.002, -0.0032, 0.0068]}
                      rotation={[-0.0695, 0.009, -0.2525]}
                    >
                      <mesh
                        name="m3820_D4"
                        castShadow
                        receiveShadow
                        geometry={nodes.m3820_D4.geometry}
                        material={materials.phong1}
                        position={[-0.2095, -0.0999, -0.0827]}
                        rotation={[0.0003, -0.0018, Math.PI / 9]}
                      >
                        <mesh
                          name="m64567_G"
                          castShadow
                          receiveShadow
                          geometry={nodes.m64567_G.geometry}
                          material={materials.phong1}
                        >
                          <mesh
                            name="m30374_B2"
                            castShadow
                            geometry={nodes.m30374_B2.geometry}
                            material={materials.trans}
                          >
                            <meshStandardMaterial
                              color={colors.lightsaberGreen.color}
                              emissive={colors.lightsaberGreen.emissive}
                              emissiveIntensity={
                                colors.lightsaberGreen.emissiveIntensity
                              }
                              toneMapped={false}
                            />
                          </mesh>
                        </mesh>
                      </mesh>
                    </group>
                    <mesh
                      name="m3818_L2"
                      castShadow
                      receiveShadow
                      geometry={nodes.m3818_L2.geometry}
                      material={materials.phong1}
                      position={[-0.2258, -0.0652, -0.0755]}
                      rotation={[0.0003, 0, 0.1745]}
                    />
                  </group>
                  <group
                    name="CatalougeFig_Rig2017_v03Head"
                    position={[0.004, 0.0128, 0]}
                    rotation={[0, 0.0018, 0]}
                  >
                    <mesh
                      name="m3669_A"
                      castShadow
                      receiveShadow
                      geometry={nodes.m3669_A.geometry}
                      material={materials.phong1}
                      position={[-0.2399, -0.0288, -0.0759]}
                      rotation={[0, -0.0018, 0]}
                    />
                  </group>
                  <mesh
                    name="m3815_J"
                    castShadow
                    receiveShadow
                    geometry={nodes.m3815_J.geometry}
                    material={materials.phong1}
                    position={[-0.236, -0.016, -0.0755]}
                  />
                </group>
                <group
                  name="CatalougeFig_Rig2017_v03Grp_R_Leg"
                  position={[-0.004, 0.0112, -0.0001]}
                >
                  <mesh
                    name="m3816_U"
                    castShadow
                    receiveShadow
                    geometry={nodes.m3816_U.geometry}
                    material={materials.phong1}
                    position={[-0.236, -0.0112, -0.0755]}
                  />
                </group>
                <group
                  name="CatalougeFig_Rig2017_v03Grp_L_Leg"
                  position={[0.004, 0.0112, -0.0002]}
                >
                  <mesh
                    name="m3817_V"
                    castShadow
                    receiveShadow
                    geometry={nodes.m3817_V.geometry}
                    material={materials.phong1}
                    position={[-0.244, -0.0112, -0.0755]}
                  />
                </group>
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  );
}
