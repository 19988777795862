import { LightenDarkenColor } from 'lighten-darken-color';

export const lightsaberGreen = {
  color: LightenDarkenColor('#2FF923', 180),
  emissive: '#2FF923',
  emissiveIntensity: 5,
};

export const lightsaberRed = {
  color: LightenDarkenColor('#e3202a', 180),
  emissive: '#e3202a',
  emissiveIntensity: 5,
};
