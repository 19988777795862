/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useRef, useEffect, useMemo, useContext } from 'react';
import { useThree, useFrame, useLoader } from '@react-three/fiber';
import {
  useGLTF,
  useTexture,
  useMask,
  PositionalAudio,
} from '@react-three/drei';

import {
  AudioLoader,
  Plane,
  Vector3,
  AnimationMixer,
  MeshBasicMaterial,
  MeshStandardMaterial,
  MeshPhysicalMaterial,
  DoubleSide,
} from 'three';
import { AssetsContext } from '@/components/ar/Assets';
import * as paths from '@/config/paths';
import { useAnimations } from '@/components/ar/utils/useAnimations';
import { useAudioLoop } from '@/components/ar/utils/useAudioLoop';
import * as colors from '@/config/colors';
import { LoopFlash } from '@/components/ar/utils/LoopFlash';

const path = paths.box_throne;

export function ThroneModel(props) {
  const group = useRef();
  const { scene, nodes, materials, animations } = useGLTF(path);
  // const assetContext = useContext(AssetsContext);
  // const { scene, nodes, materials, animations } = assetContext.models.box_throne;

  const { mixer, action } = useAnimations(group, animations, 1.25);
  const refAudio = useRef(null);
  useAudioLoop(refAudio, mixer);

  // **************************************************
  // MATERIALS
  // **************************************************
  useEffect(() => {
    for (let id in materials) {
      materials[id].roughness = 0.2;
      materials[id].metalness = 0;
      materials[id].envMapIntensity = 1.25;
    }
  }, [materials]);

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
    >
      <PositionalAudio
        ref={refAudio}
        url={paths.audio_box_throne}
        distance={10000}
      />
      <LoopFlash mixer={mixer} />
      <group name="Root_Scene">
        <group
          name="CatalougeFig_Rig2017_v01Body"
          position={[0.0025, 0.3169, -0.1792]}
          scale={4.7152}
        >
          <group
            name="CatalougeFig_Rig2017_v01Hip"
            position={[-0.004, 0.0007, -0.0004]}
            rotation={[-0.0472, 0, 0]}
          >
            <group
              name="CatalougeFig_Rig2017_v01LeftArm"
              position={[0.0103, 0.009, 0]}
              rotation={[0.3548, 0.0612, 0.1636]}
            >
              <group
                name="CatalougeFig_Rig2017_v01LeftHand"
                position={[0.002, -0.0032, 0.0068]}
                rotation={[0.0048, 0.0018, 0.1745]}
              >
                <mesh
                  name="m3820_D3"
                  castShadow
                  receiveShadow
                  geometry={nodes.m3820_D3.geometry}
                  material={materials.phong1}
                  position={[0, 0, 0]}
                  rotation={[-0.0003, -0.0018, -Math.PI / 9]}
                />
              </group>
              <mesh
                name="m3819_N"
                castShadow
                receiveShadow
                geometry={nodes.m3819_N.geometry}
                material={materials.phong1}
                rotation={[-0.0003, 0, -0.1745]}
              />
            </group>
            <group
              name="CatalougeFig_Rig2017_v01RightArm"
              position={[-0.0023, 0.009, 0]}
              rotation={[0.2553, -0.0445, -0.1688]}
            >
              <group
                name="CatalougeFig_Rig2017_v01RightHand"
                position={[-0.002, -0.0032, 0.0068]}
                rotation={[0.0053, 0.0018, -0.1745]}
              >
                <mesh
                  name="m3820_D"
                  castShadow
                  receiveShadow
                  geometry={nodes.m3820_D.geometry}
                  material={materials.phong1}
                  position={[0, 0, 0]}
                  rotation={[0.0003, -0.0018, Math.PI / 9]}
                />
              </group>
              <mesh
                name="m3818_L1"
                castShadow
                receiveShadow
                geometry={nodes.m3818_L1.geometry}
                material={materials.phong1}
                rotation={[0.0003, 0, 0.1745]}
              />
            </group>
            <group
              name="CatalougeFig_Rig2017_v01Head"
              position={[0.004, 0.0128, 0]}
              rotation={[0.1212, 0.2568, 0.0545]}
            >
              <mesh
                name="m3626_N2"
                castShadow
                receiveShadow
                geometry={nodes.m3626_N2.geometry}
                material={materials.phong1}
                position={[0, 0.0003, 0]}
                rotation={[0, -0.0018, 0]}
              />
            </group>
            <mesh
              name="m3815_J1"
              castShadow
              receiveShadow
              geometry={nodes.m3815_J1.geometry}
              material={materials.phong1}
            />
          </group>
        </group>
        <group
          name="CatalougeFig_Rig2017_v02Mesh"
          position={[-0.2617, 0.4035, 0.0505]}
          scale={4.7152}
        >
          <group
            name="CatalougeFig_Rig2017_v02Body"
            position={[0.0306, -0.0229, -0.001]}
            rotation={[-Math.PI, 1.2948, -Math.PI]}
          >
            <group
              name="locator2"
              position={[0.0004, 0.006, 0.0033]}
            >
              <group
                name="CatalougeFig_Rig2017_v02Grp_L_Leg"
                position={[0.0036, -0.01, -0.0034]}
                rotation={[-0.2791, 0, 0]}
              >
                <mesh
                  name="m3817_V1"
                  castShadow
                  receiveShadow
                  geometry={nodes.m3817_V1.geometry}
                  material={materials.phong1}
                  position={[0, -0.0112, 0]}
                />
              </group>
              <group
                name="CatalougeFig_Rig2017_v02Grp_R_Leg"
                position={[-0.0044, -0.01, -0.0034]}
                rotation={[0.5222, -0.06, 0.0121]}
              >
                <mesh
                  name="m3816_U1"
                  castShadow
                  receiveShadow
                  geometry={nodes.m3816_U1.geometry}
                  material={materials.phong1}
                  position={[0, -0.0112, 0]}
                />
              </group>
              <group
                name="CatalougeFig_Rig2017_v02Hip"
                position={[-0.0045, -0.0053, -0.0035]}
                rotation={[0.2211, 0, 0]}
              >
                <group
                  name="CatalougeFig_Rig2017_v02LeftArm"
                  position={[0.0103, 0.009, 0]}
                  rotation={[0.8001, 0.1258, 0.1213]}
                >
                  <group
                    name="CatalougeFig_Rig2017_v02LeftHand"
                    position={[0.002, -0.0032, 0.0068]}
                    rotation={[0.0041, 0.0018, 0.1745]}
                  >
                    <mesh
                      name="m3820_D2"
                      castShadow
                      receiveShadow
                      geometry={nodes.m3820_D2.geometry}
                      material={materials.phong1}
                      position={[0, 0, -0.0001]}
                      rotation={[-0.0003, -0.0018, -Math.PI / 9]}
                    />
                  </group>
                  <mesh
                    name="m3819_N2"
                    castShadow
                    receiveShadow
                    geometry={nodes.m3819_N2.geometry}
                    material={materials.phong1}
                    rotation={[-0.0003, 0, -0.1745]}
                  />
                </group>
                <group
                  name="CatalougeFig_Rig2017_v02RightArm"
                  position={[-0.0023, 0.009, 0]}
                  rotation={[0.3869, -0.0664, -0.1615]}
                >
                  <group
                    name="CatalougeFig_Rig2017_v02RightHand"
                    position={[-0.002, -0.0032, 0.0068]}
                    rotation={[-0.1072, 0.0069, -0.2934]}
                  >
                    <mesh
                      name="m3820_D5"
                      castShadow
                      receiveShadow
                      geometry={nodes.m3820_D5.geometry}
                      material={materials.phong1}
                      position={[0, 0, -0.0001]}
                      rotation={[0.0003, -0.0018, 0.5066]}
                    >
                      <mesh
                        name="m64567_G1"
                        castShadow
                        receiveShadow
                        geometry={nodes.m64567_G1.geometry}
                        material={materials.phong1}
                        position={[-0.0011, -0.0061, 0.0023]}
                      >
                        <mesh
                          name="m30374_B3"
                          castShadow
                          receiveShadow
                          geometry={nodes.m30374_B3.geometry}
                          material={materials.phong1}
                          position={[0.0024, 0.0135, 0.0037]}
                        >
                          <meshStandardMaterial
                            color={colors.lightsaberRed.color}
                            emissive={colors.lightsaberRed.emissive}
                            emissiveIntensity={
                              colors.lightsaberRed.emissiveIntensity
                            }
                            toneMapped={false}
                          />
                        </mesh>
                      </mesh>
                    </mesh>
                  </group>
                  <mesh
                    name="m3818_L"
                    castShadow
                    receiveShadow
                    geometry={nodes.m3818_L.geometry}
                    material={materials.phong1}
                    rotation={[0.0003, 0, 0.1745]}
                  />
                </group>
                <group
                  name="CatalougeFig_Rig2017_v02Head"
                  position={[0.004, 0.0128, 0]}
                >
                  <mesh
                    name="m19916_C"
                    castShadow
                    receiveShadow
                    geometry={nodes.m19916_C.geometry}
                    material={materials.phong1}
                    position={[-0.0001, 0.0103, 0]}
                    rotation={[0, -0.0018, 0]}
                  />
                </group>
                <mesh
                  name="m1010488_H"
                  castShadow
                  receiveShadow
                  geometry={nodes.m1010488_H.geometry}
                  material={materials.phong1}
                  morphTargetDictionary={nodes.m1010488_H.morphTargetDictionary}
                  morphTargetInfluences={nodes.m1010488_H.morphTargetInfluences}
                  position={[0.004, 0.0128, 0]}
                >
                  <meshPhongMaterial
                    color={materials.phong1.color}
                    map={materials.phong1.map}
                  />
                </mesh>
                <mesh
                  name="m3814_T1"
                  castShadow
                  receiveShadow
                  geometry={nodes.m3814_T1.geometry}
                  material={materials.phong1}
                />
              </group>
            </group>
          </group>
        </group>
        <group
          name="CatalougeFig_Rig2017_v03Mesh"
          position={[0.2253, 0.2968, -0.0163]}
          scale={4.7152}
        >
          <group
            name="CatalougeFig_Rig2017_v03Body"
            position={[-0.017, -0.0005, 0.0009]}
            rotation={[0, -1.0912, 0]}
          >
            <group
              name="locator1"
              position={[-0.0007, 0.0047, 0.0003]}
            >
              <group
                name="CatalougeFig_Rig2017_v03Grp_L_Leg"
                position={[0.0047, -0.0087, -0.0004]}
              >
                <mesh
                  name="m3817_V"
                  castShadow
                  receiveShadow
                  geometry={nodes.m3817_V.geometry}
                  material={materials.phong1}
                  position={[0, -0.0112, 0]}
                />
              </group>
              <group
                name="CatalougeFig_Rig2017_v03Grp_R_Leg"
                position={[-0.0033, -0.0087, -0.0004]}
              >
                <mesh
                  name="m3816_U"
                  castShadow
                  receiveShadow
                  geometry={nodes.m3816_U.geometry}
                  material={materials.phong1}
                  position={[0, -0.0112, 0]}
                />
              </group>
              <group
                name="CatalougeFig_Rig2017_v03Hip"
                position={[-0.0033, -0.0039, -0.0003]}
                rotation={[0.0367, 0, 0]}
              >
                <group
                  name="CatalougeFig_Rig2017_v03LeftArm"
                  position={[0.0103, 0.009, 0]}
                  rotation={[1.265, 0.1666, 0.0523]}
                >
                  <group
                    name="CatalougeFig_Rig2017_v03LeftHand"
                    position={[0.002, -0.0032, 0.0068]}
                    rotation={[0.0043, 0.0018, 0.1745]}
                  >
                    <mesh
                      name="m3820_D1"
                      castShadow
                      receiveShadow
                      geometry={nodes.m3820_D1.geometry}
                      material={materials.phong1}
                      position={[0, 0, 0]}
                      rotation={[-0.0003, -0.0018, -Math.PI / 9]}
                    />
                  </group>
                  <mesh
                    name="m3819_N1"
                    castShadow
                    receiveShadow
                    geometry={nodes.m3819_N1.geometry}
                    material={materials.phong1}
                    rotation={[-0.0003, 0, -0.1745]}
                  />
                </group>
                <group
                  name="CatalougeFig_Rig2017_v03RightArm"
                  position={[-0.0023, 0.009, 0]}
                  rotation={[0.4948, -0.0835, -0.1534]}
                >
                  <group
                    name="CatalougeFig_Rig2017_v03RightHand"
                    position={[-0.002, -0.0032, 0.0068]}
                    rotation={[0.0074, 0.0018, -0.1745]}
                  >
                    <mesh
                      name="m3820_D4"
                      castShadow
                      receiveShadow
                      geometry={nodes.m3820_D4.geometry}
                      material={materials.phong1}
                      position={[0, 0, 0]}
                      rotation={[0.0003, -0.0018, Math.PI / 9]}
                    >
                      <mesh
                        name="m64567_G"
                        castShadow
                        receiveShadow
                        geometry={nodes.m64567_G.geometry}
                        material={materials.phong1}
                        position={[-0.0011, -0.0061, 0.0023]}
                      >
                        <mesh
                          name="m30374_B2"
                          castShadow
                          receiveShadow
                          geometry={nodes.m30374_B2.geometry}
                          material={materials.phong1}
                          position={[0.0024, 0.0135, 0.0037]}
                        >
                          <meshStandardMaterial
                            color={colors.lightsaberGreen.color}
                            emissive={colors.lightsaberGreen.emissive}
                            emissiveIntensity={
                              colors.lightsaberGreen.emissiveIntensity
                            }
                            toneMapped={false}
                          />
                        </mesh>
                      </mesh>
                    </mesh>
                  </group>
                  <mesh
                    name="m3818_L2"
                    castShadow
                    receiveShadow
                    geometry={nodes.m3818_L2.geometry}
                    material={materials.phong1}
                    rotation={[0.0003, 0, 0.1745]}
                  />
                </group>
                <group
                  name="CatalougeFig_Rig2017_v03Head"
                  position={[0.004, 0.0128, 0]}
                  rotation={[0, 0.0018, 0]}
                >
                  <mesh
                    name="m3669_A"
                    castShadow
                    receiveShadow
                    geometry={nodes.m3669_A.geometry}
                    material={materials.phong1}
                    position={[0, 0.0096, 0]}
                    rotation={[0, -0.0018, 0]}
                  />
                </group>
                <mesh
                  name="m3815_J"
                  castShadow
                  receiveShadow
                  geometry={nodes.m3815_J.geometry}
                  material={materials.phong1}
                />
              </group>
            </group>
          </group>
        </group>
        <mesh
          name="throne"
          castShadow
          receiveShadow
          geometry={nodes.throne.geometry}
          material={materials.phong1}
          position={[-0.3179, 0.2062, 0.0425]}
          scale={4.7152}
        />
      </group>
    </group>
  );
}
