/*

XR8_Layer

This is needed to encapsulate the effect composer in to a render target

*/

import { useRef, useState } from 'react';
import { Scene, Color, sRGBEncoding } from 'three';
import { useThree, useFrame, createPortal } from '@react-three/fiber';
import { useFBO } from '@react-three/drei';
import { useComposer } from '@/components/ar/xr8/hooks/useComposer';
import {
  EffectComposer,
  BlendFunction,
  RenderPass,
  EffectPass,
  CopyPass,
  BloomEffect,
  VignetteEffect,
} from 'postprocessing';
import {
  SSGIEffect,
  TRAAEffect,
  MotionBlurEffect,
  VelocityDepthNormalPass,
} from 'realism-effects';

import { fov, cameraZ } from '@/components/ar/utils/helpers';
import { XR8_Camera } from '@/components/ar/xr8/XR8_Camera';
import { CameraContext } from '@/components/ar/xr8/context/CameraContext';

export function XR8_Layer(props) {
  const ratio = window.innerWidth / window.innerHeight;

  const { gl, scene, camera } = useThree();

  const refMesh = useRef(null);
  const layerCamera = useRef(null);
  const [layerScene] = useState(() => new Scene());

  const renderTarget = useFBO({
    // encoding: gl.outputEncoding,
  });

  const composer = useComposer(
    gl,
    layerScene,
    layerCamera.current,
    renderTarget
  );

  useFrame((state) => {
    if (!composer) return;
    // if (!props.disabled) {
    //   composer.render();
    // } else {
    //   gl.setRenderTarget(renderTarget);
    //   gl.clear(true, true);
    //   gl.render(layerScene, layerCamera.current);
    // }

    composer.render();

    // gl.setRenderTarget(renderTarget);
    // gl.clear(true, true);
    // gl.render(layerScene, layerCamera.current);

    gl.setRenderTarget(null);
    gl.render(scene, camera);
  }, 1);

  // useFrame(() => {
  //   gl.setRenderTarget(null);
  //   gl.render(scene, camera);
  // }, 2);

  const priority = 1;
  const compute = (event, state, previous) => {
    // First we call the previous state-onion-layers compute, this is what makes it possible to nest portals
    if (!previous.raycaster.camera)
      previous.events.compute(
        event,
        previous,
        previous.previousRoot.getState()
      );
    // We run a quick check against the textured plane itself, if it isn't hit there's no need to raycast at all
    const [intersection] = previous.raycaster.intersectObject(refMesh.current);
    if (!intersection) return false;
    // We take that hits uv coords, set up this layers raycaster, et voilà, we have raycasting with perspective shift
    const uv = intersection.uv;
    state.raycaster.setFromCamera(
      state.pointer.set(uv.x * 2 - 1, uv.y * 2 - 1),
      layerCamera.current
    );
  };

  return (
    <>
      <mesh
        ref={refMesh}
        position={[0, 0, 0]}
      >
        <planeGeometry args={[ratio, 1]} />
        {/*  */}
        <meshBasicMaterial
          map={renderTarget.texture}
          transparent={true}
          opacity={1}
          toneMapped={false}
        />
      </mesh>

      {createPortal(
        <CameraContext.Provider value={layerCamera}>
          {props.children}
          {/* <PerspectiveCamera
						manual
						ref={layerCamera}
						fov={fov}
						aspect={ratio / 1}
						position={[0, 0, cameraZ]}
					/> */}
        </CameraContext.Provider>,
        layerScene,
        {
          events: { compute, priority },
        }
      )}
    </>
  );
}
