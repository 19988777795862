import './index.sass';
import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { PopupHome } from '@/components/ui/_general/PopupHome';

export const BackButtons = function (props) {
  const [showHome, setShowHome] = useState(false);

  return (
    <>
      <div className="BackButtons">
        <button
          className="back"
          onClick={() => props.onBack?.()}
        >
          <img src="/assets/images/ui/_general/back.svg" />
        </button>
        <div className="divider" />
        <button
          className="home"
          onClick={() => setShowHome(true)}
        >
          <img src="/assets/images/ui/_general/home.svg" />
        </button>
      </div>

      <AnimatePresence>
        {showHome && (
          <PopupHome
            setScene={props.setScene}
            onClose={() => setShowHome(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};
