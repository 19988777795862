import {
  useEffect,
  useMemo,
  useState,
  useRef,
  Children,
  cloneElement,
} from 'react';
import { useThree } from '@react-three/fiber';
import { Environment } from '@react-three/drei';

import { ThroneFrame } from '@/components/ar/scenes/throne/ThroneFrame';

export function XR8_ImageTarget(props) {
  const { XR8 } = window;
  const ref = useRef(null);
  const childrenRefs = useRef(null);

  const [detected, setDetected] = useState(null);

  // useEffect(() => {
  //   ref.current.visible = false;
  // }, [ref]);

  const { setTargetFound } = props;

  const module = useMemo(() => {
    // const showTarget = ({ detail }) => {
    //   if (detail.name === props.name) {
    //     ref.current.position.copy(detail.position);
    //     ref.current.quaternion.copy(detail.rotation);
    //     ref.current.scale.set(detail.scale, detail.scale, detail.scale);
    //     ref.current.visible = true;
    //   }
    // };

    // const hideTarget = ({ detail }) => {
    //   if (detail.name === props.name) {
    //     ref.current.visible = false;
    //   }
    // };

    const showTarget = ({ detail }) => {
      if (ref.current) {
        ref.current.position.copy(detail.position);
        ref.current.quaternion.copy(detail.rotation);
        ref.current.scale.set(detail.scale, detail.scale, detail.scale);
      }
      setTargetFound?.(detail.name);
    };

    const hideTarget = ({ detail }) => {
      setTargetFound?.(null);
    };

    return {
      name: `XR8_ImageTarget`,
      listeners: [
        { event: 'reality.imagefound', process: showTarget },
        { event: 'reality.imageupdated', process: showTarget },
        { event: 'reality.imagelost', process: hideTarget },
      ],
    };
  }, [setTargetFound]);

  useEffect(() => {
    XR8.addCameraPipelineModule(module);
    return () => {
      XR8.removeCameraPipelineModule(module.name);
    };
  }, [XR8, module]);

  return (
    <>
      <group
        name="image-target"
        ref={ref}
      >
        {props.children}
      </group>
    </>
  );
}
