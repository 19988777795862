import { useEffect, useContext, useMemo, useRef, useCallback } from 'react';
import { Vector2, MathUtils } from 'three';
import { useThree, useFrame } from '@react-three/fiber';
import { LogEase } from '@/helpers/LogEase';
import { CameraContext } from '@/components/ar/xr8/context/CameraContext';

import { ThroneFrame } from '@/components/ar/scenes/throne/ThroneFrame';
import { gsap } from 'gsap';

export function XR8_PlaceGround(props) {
  const { gl, invalidate, camera, raycaster } = useThree();

  const refCamera = useContext(CameraContext);

  const module = useMemo(() => {
    const onAttach = function (evt) {
      camera.position.set(0, 1.6, 2);
      XR8.XrController.updateCameraProjectionMatrix({
        origin: camera.position,
        facing: camera.quaternion,
      });
      // XR8.XrController.recenter();
      refPlace.current.scale.set(0.001, 0.001, 0.001);
    };

    return {
      name: `XR8_PlaceGround`,
      onAttach,
    };
  }, []);

  useEffect(() => {
    XR8.addCameraPipelineModule(module);

    return () => {
      XR8.removeCameraPipelineModule(module.name);
    };
  }, [module]);

  const refGround = useRef(null);
  const refPlace = useRef(null);
  const refCursor = useRef(null);

  const onClick = useCallback(
    (evt) => {
      if (props.placed) {
        refPlace.current.scale.set(0.001, 0.001, 0.001);
        // XR8.XrController.recenter();
        props.onUnplaced?.();
        return;
      }

      // refPlace.current.position.x = evt.intersections[0].point.x;
      // refPlace.current.position.z = evt.intersections[0].point.z;

      refPlace.current.position.x = cursor.current.x.current;
      refPlace.current.position.z = cursor.current.z.current;

      refPlace.current.scale.set(1, 1, 1);
      // invalidate();

      props.onPlaced?.();
    },
    [props.placed]
  );

  useEffect(() => {
    gl.domElement.addEventListener('click', onClick);

    return () => {
      gl.domElement.removeEventListener('click', onClick);
    };
  }, [onClick]);

  const center = useRef(new Vector2(0, 0));

  const cursor = useRef({
    x: new LogEase({ ease: 0.2 }),
    z: new LogEase({ ease: 0.2 }),
  });

  useFrame(() => {
    raycaster.setFromCamera(center.current, refCamera.current);

    const intersections = raycaster.intersectObjects([refGround.current]);

    if (intersections.length) {
      cursor.current.x.update(intersections[0].point.x);
      cursor.current.z.update(intersections[0].point.z);

      refCursor.current.position.x = cursor.current.x.current;
      refCursor.current.position.z = cursor.current.z.current;

      // invalidate();
    }
  });

  return (
    <group visible={props.foundScale}>
      <mesh
        ref={refGround}
        position={[0, -0.99, 0]}
        visible={false}
        // onClick={onClick}
      >
        <boxGeometry args={[1000, 2, 1000]} />
        {/* <shadowMaterial opacity={0.2} /> */}
        {/* <meshBasicMaterial
          color={'green'}
          transparent={true}
          opacity={0.4}
        /> */}
      </mesh>

      <mesh
        ref={refCursor}
        rotation={[-Math.PI / 2, 0, 0]}
        visible={!props.placed}
      >
        <ringGeometry
          args={[0.2, 0.3, 32]}
          scale={[0.1, 0.1, 0.1]}
        />
        <meshBasicMaterial
          color={'#F9D132'}
          toneMapped={false}
          flatShading={true}
        />
      </mesh>

      <group ref={refPlace}>{props.children}</group>
    </group>
  );
}
