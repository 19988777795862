import { create } from 'zustand';
import { getUrlString } from '@/helpers/UrlParam';

export const useAppStore = create((set) => ({
  mode: getUrlString('mode', 'box'),
  scene: getUrlString('scene', 'throne'),
  setMode: (val) =>
    set((state) => {
      console.log('setMode', val);
      return { mode: val };
    }),
  setScene: (val) =>
    set((state) => {
      console.log('setScene', val);
      return { scene: val };
    }),
}));
