/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useRef, useEffect, useContext } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useGLTF, useMask, PositionalAudio } from '@react-three/drei';
import { Plane, Vector3, AnimationMixer } from 'three';
import { AssetsContext } from '@/components/ar/Assets';
import { useAnimations } from '@/components/ar/utils/useAnimations';
import { useAudioLoop } from '@/components/ar/utils/useAudioLoop';
import { LoopFlash } from '@/components/ar/utils/LoopFlash';

import * as paths from '@/config/paths';

const path = paths.ground_speeder;

export function SpeederGroundModel(props) {
  const group = useRef();
  const { scene, nodes, materials, animations } = useGLTF(path);
  // const assetContext = useContext(AssetsContext);
  // const { scene, nodes, materials, animations } = assetContext.models.ground_speeder;

  const { mixer, action } = useAnimations(group, animations, 1, props.enabled);
  const refAudio = useRef(null);
  useAudioLoop(refAudio, mixer, props.enabled);

  // **************************************************
  // ENV MAP INTENSITY
  // **************************************************
  useEffect(() => {
    for (let id in materials) {
      // materials[id].roughness = 0;
      // materials[id].metalness = 0;
      materials[id].envMapIntensity = 1;
    }
  }, [materials]);

  useEffect(() => {});

  return (
    <group
      ref={group}
      {...props}
    >
      <PositionalAudio
        ref={refAudio}
        url={paths.audio_ground_speeder}
        distance={10000}
      />
      <LoopFlash mixer={mixer} />
      <group>
        <group
          name="Group001"
          position={[0.0053, 1.1548, 0.5435]}
          scale={6.8746}
        >
          <mesh
            name="m3815_J1"
            castShadow
            receiveShadow
            geometry={nodes.m3815_J1.geometry}
            material={materials['Material #27']}
            position={[0.0797, 0.0026, 0.0004]}
            rotation={[1.5817, 0.3131, 1.6034]}
            scale={1.453}
          />
          <mesh
            name="m3818_L"
            castShadow
            receiveShadow
            geometry={nodes.m3818_L.geometry}
            material={materials['Material #27']}
            position={[0.0609, 0.0347, -0.029]}
            rotation={[1.5789, 0.3808, 1.6163]}
            scale={1.453}
          />
          <mesh
            name="m3820_D"
            castShadow
            receiveShadow
            geometry={nodes.m3820_D.geometry}
            material={materials['Material #27']}
            position={[0.0437, 0.0215, -0.0319]}
            rotation={[2.8733, 0.1537, 1.2188]}
            scale={1.453}
          />
          <mesh
            name="m3819_N2"
            castShadow
            receiveShadow
            geometry={nodes.m3819_N2.geometry}
            material={materials['Material #27']}
            position={[0.0596, 0.0331, 0.0299]}
            rotation={[1.5788, 0.4337, 1.5832]}
            scale={1.453}
          />
          <mesh
            name="m3820_D2"
            castShadow
            receiveShadow
            geometry={nodes.m3820_D2.geometry}
            material={materials['Material #27']}
            position={[0.043, 0.0189, 0.0313]}
            rotation={[0.1682, 0.0632, 2.0036]}
            scale={1.453}
          />
          <mesh
            name="m48156_F"
            castShadow
            receiveShadow
            geometry={nodes.m48156_F.geometry}
            material={materials['Material #27']}
            position={[0.0516, 0.0798, 0.0011]}
            rotation={[1.5817, 0.3131, 1.6034]}
            scale={1.453}
          >
            <mesh
              name="m3626_N1"
              castShadow
              receiveShadow
              geometry={nodes.m3626_N1.geometry}
              material={materials['Material #27']}
              position={[0, -0.002, 0.0043]}
            />
          </mesh>
          <mesh
            name="m3816_U1"
            castShadow
            receiveShadow
            geometry={nodes.m3816_U1.geometry}
            material={materials['Material #27']}
            position={[0.07, -0.0123, -0.0153]}
            rotation={[1.6412, -1.0101, 1.6292]}
            scale={1.453}
          />
          <mesh
            name="m29119_H5"
            castShadow
            receiveShadow
            geometry={nodes.m29119_H5.geometry}
            material={materials['Material #27']}
            position={[-0.1881, -0.0324, 0.0394]}
            rotation={[1.592, -0.0076, 1.551]}
            scale={1.453}
          />
          <mesh
            name="m3817_V2"
            castShadow
            receiveShadow
            geometry={nodes.m3817_V2.geometry}
            material={materials['Material #27']}
            position={[0.0691, -0.0129, 0.0148]}
            rotation={[1.641, -1.0083, 1.629]}
            scale={1.453}
          />
          <mesh
            name="m3814_T2"
            castShadow
            receiveShadow
            geometry={nodes.m3814_T2.geometry}
            material={materials['Material #27']}
            position={[0.0667, 0.0426, 0.0008]}
            rotation={[1.5817, 0.3131, 1.6034]}
            scale={1.453}
          />
        </group>
      </group>
    </group>
  );
}
