import { useEffect, forwardRef, useMemo, useContext } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { PerspectiveCamera, CameraControls } from '@react-three/drei';
import { Clock } from 'three';
import { fov, cameraZ } from '@/components/ar/utils/helpers';
import { CameraContext } from '@/components/ar/xr8/context/CameraContext';

export const XR8_LayerCameraDesktop = (props) => {
  const ref = useContext(CameraContext);
  const { gl, invalidate } = useThree();

  // useFrame(() => {
  //   invalidate();
  // });

  return (
    <>
      <PerspectiveCamera
        name="XR8_Camera"
        className="XR8_Camera"
        ref={ref}
        fov={fov}
        position={[0, 0, cameraZ]}
        {...props}
      />
    </>
  );
};
