import { getUrlString, getUrlInt } from '@/helpers/UrlParam';

// 01 - 0.1% error, 0.1 ratio
// 0001 - 0.0001% error, 0.1 ratio
// none - no simplification
// auto - 0.1% error, 0.5 ratio
const quality = getUrlInt('quality', 2);
let simplify
switch (quality) {
  case 1:
    simplify = '01'
    break
  case 2:
    simplify = 'auto'
    break
  case 3:
    simplify = '0001'
    break
  case 4:
    simplify = 'none'
    break
}
simplify = getUrlString('simplify', simplify)

console.log(`quality: ${quality}, simplify: ${simplify}`)
console.log()

export const box_speeder = `/assets/models/simplify-${simplify}/box_speeder-o.glb`;
export const box_throne = `/assets/models/simplify-${simplify}/box_throne-o.glb`;
export const box_xwing = `/assets/models/simplify-${simplify}/box_xwing-o.glb`;

export const ground_speeder = `/assets/models/simplify-0001/ground_speeder-o.glb`;
export const ground_throne = `/assets/models/simplify-0001/ground_throne-o.glb`;
export const ground_xwing = `/assets/models/simplify-0001/ground_xwing-o.glb`;

export const frame_speeder = '/assets/images/ar/speeder/speeder-frame.png';
export const frame_throne = '/assets/images/ar/throne/throne-frame.png';
export const frame_xwing = '/assets/images/ar/xwing/xwing-frame.png';
export const throne_texture = '/assets/models/75352_texture_02-min.png';
export const xwing_texture = '/assets/models/Xwing_floor_v02-o-processed0.jpg';

export const audio_box_speeder = '/assets/sounds/box_speeder.wav';
export const audio_box_throne = '/assets/sounds/box_throne.wav';
export const audio_box_xwing = '/assets/sounds/box_xwing.wav';
export const audio_ground_speeder = '/assets/sounds/ground_speeder.wav';
export const audio_ground_throne = '/assets/sounds/ground_throne.wav';
export const audio_ground_xwing = '/assets/sounds/ground_xwing.wav';


// export const imgArThroneThroneBg = 'assets/images/ar/throne/throne-bg.jpg'
// export const imgArXwingXwingBg = 'assets/images/ar/xwing/xwing-bg.jpg'
// export const imgUiGeneralLego = 'assets/images/ui/_general/lego.jpg'
// export const imgUiGeneralLockup = 'assets/images/ui/_general/lockup.png'
// export const imgUiArBoxButtonStart = 'assets/images/ui/ar/box/button-start.png'
// export const imgUiArBoxSpeeder = 'assets/images/ui/ar/box/speeder.png'
// export const imgUiArBoxThrone = 'assets/images/ui/ar/box/throne.png'
// export const imgUiArBoxXwing = 'assets/images/ui/ar/box/xwing.png'
// export const imgUiArHeaderLandscape = 'assets/images/ui/ar/header-landscape.png'
// export const imgUiArLegoMpQr = 'assets/images/ui/ar/lego-mp-qr.png'
// export const imgUiArLockup = 'assets/images/ui/ar/lockup.png'
// export const imgUiArPosterPosterHeader = 'assets/images/ui/ar/header-portrait.png'
// export const imgUiArRotj40 = 'assets/images/ui/ar/rotj40.png'
// export const imgUiArSizeXwing = 'assets/images/ui/ar/size-xwing.png'
// export const imgUiHomeBgDeathstar = 'assets/images/ui/home/bg/deathstar.png'
// export const imgUiHomeBgFrame = 'assets/images/ui/home/bg/frame.png'
// export const imgUiHomeBgStars = 'assets/images/ui/home/bg/stars.jpg'
// export const imgUiHomeBgTiefighters = 'assets/images/ui/home/bg/tiefighters.jpg'
// export const imgUiHomeLandingLuke = 'assets/images/ui/home/landing/luke.png'
// export const imgUiHomeLandingPieceBlue = 'assets/images/ui/home/landing/piece-blue.png'
// export const imgUiHomeLandingPieceRed = 'assets/images/ui/home/landing/piece-red.png'
// export const imgUiHomeLandingPieceYellow = 'assets/images/ui/home/landing/piece-yellow.png'
// export const imgUiHomeLandingVader = 'assets/images/ui/home/landing/vader.png'
// export const imgUiHomeSelectModeModeBox = 'assets/images/ui/home/select-mode/mode-box.png'
// export const imgUiHomeSelectModeModeGround = 'assets/images/ui/home/select-mode/mode-ground.png'
// export const imgUiHomeSelectGroundSceneSpeeder = 'assets/images/ui/home/select-ground-scene/speeder.png'
// export const imgUiHomeSelectGroundSceneThrone = 'assets/images/ui/home/select-ground-scene/throne.png'
// export const imgUiHomeSelectGroundSceneXwing = 'assets/images/ui/home/select-ground-scene/xwing.png'


export const imgArThroneThroneBg = 'assets/images-next/ar/throne/throne-bg.webp'
export const imgArXwingXwingBg = 'assets/images-next/ar/xwing/xwing-bg.webp'
export const imgUiGeneralLego = 'assets/images-next/ui/_general/lego.webp'
export const imgUiGeneralLockup = 'assets/images-next/ui/_general/lockup.webp'
export const imgUiArBoxButtonStart = 'assets/images-next/ui/ar/box/button-start.webp'
export const imgUiArBoxSpeeder = 'assets/images-next/ui/ar/box/speeder.webp'
export const imgUiArBoxThrone = 'assets/images-next/ui/ar/box/throne.webp'
export const imgUiArBoxXwing = 'assets/images-next/ui/ar/box/xwing.webp'
export const imgUiArHeaderLandscape = 'assets/images-next/ui/ar/header-landscape.webp'
export const imgUiArLegoMpQr = 'assets/images-next/ui/ar/lego-mp-qr.webp'
export const imgUiArLockup = 'assets/images-next/ui/ar/lockup.webp'
export const imgUiArHeaderPortrait = 'assets/images-next/ui/ar/header-portrait.webp'
export const imgUiArRotj40 = 'assets/images-next/ui/ar/rotj40.webp'
export const imgUiArSizeXwing = 'assets/images-next/ui/ar/size-xwing.webp'
export const imgUiHomeBgDeathstar = 'assets/images-next/ui/home/bg/deathstar.webp'
export const imgUiHomeBgFrame = 'assets/images-next/ui/home/bg/frame.webp'
export const imgUiHomeBgStars = 'assets/images-next/ui/home/bg/stars.webp'
export const imgUiHomeBgTiefighters = 'assets/images-next/ui/home/bg/tiefighters.webp'
export const imgUiHomeLandingLuke = 'assets/images-next/ui/home/landing/luke.webp'
export const imgUiHomeLandingPieceBlue = 'assets/images-next/ui/home/landing/piece-blue.webp'
export const imgUiHomeLandingPieceRed = 'assets/images-next/ui/home/landing/piece-red.webp'
export const imgUiHomeLandingPieceYellow = 'assets/images-next/ui/home/landing/piece-yellow.webp'
export const imgUiHomeLandingVader = 'assets/images-next/ui/home/landing/vader.webp'
export const imgUiHomeSelectModeModeBox = 'assets/images-next/ui/home/select-mode/mode-box.webp'
export const imgUiHomeSelectModeModeGround = 'assets/images-next/ui/home/select-mode/mode-ground.webp'
export const imgUiHomeSelectGroundSceneSpeeder = 'assets/images-next/ui/home/select-ground-scene/speeder.webp'
export const imgUiHomeSelectGroundSceneThrone = 'assets/images-next/ui/home/select-ground-scene/throne.webp'
export const imgUiHomeSelectGroundSceneXwing = 'assets/images-next/ui/home/select-ground-scene/xwing.webp'
export const imgUiHomeSelectGroundSceneSpeederInner = 'assets/images-next/ui/home/select-ground-scene/speeder-inner.webp'
export const imgUiHomeSelectGroundSceneThroneInner = 'assets/images-next/ui/home/select-ground-scene/throne-inner.webp'
export const imgUiHomeSelectGroundSceneXwingInner = 'assets/images-next/ui/home/select-ground-scene/xwing-inner.webp'
export const imgUiHomeSelectBoxSceneSpeeder = 'assets/images-next/ui/home/select-box-scene/speeder.webp'
export const imgUiHomeSelectBoxSceneXwing = 'assets/images-next/ui/home/select-box-scene/throne.webp'
export const imgUiHomeSelectBoxSceneThrone = 'assets/images-next/ui/home/select-box-scene/xwing.webp'
export const imgUiPopupHome = 'assets/images-next/ui/_general/popup-home.webp'


