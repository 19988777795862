import { Suspense, useRef, useState } from 'react';
import { MathUtils, DoubleSide } from 'three';
import { Environment, PivotControls, useMask } from '@react-three/drei';

import { EffectComposer, Bloom, Vignette } from '@react-three/postprocessing';

import { Reflector } from '@/components/ar/Reflector';

import { SpeederModel } from './SpeederModel';
import { SpeederFrame } from './SpeederFrame';
import { useFrame } from '@react-three/fiber';
import { useLightPos } from '@/components/ar/utils/useLightPos.jsx';

const presets = {
  rembrandt: {
    main: [1, 2, 1],
    fill: [-2, -0.5, -2],
  },
  portrait: {
    main: [-1, 2, 0.5],
    fill: [-1, 0.5, -1.5],
  },
  upfront: {
    main: [0, 2, 1],
    fill: [-1, 0.5, -1.5],
  },
  soft: {
    main: [-2, 4, 4],
    fill: [-1, 0.5, -1.5],
  },
};

export function SpeederScene() {
  // *******************************************************
  // SPOT LIGHT
  // *******************************************************
  const intensity = 3;
  const config = presets.upfront;
  const radius = 6;
  const shadows = 'contact';
  const shadowBias = -0.0001;
  const normalBias = 0;
  const shadowSize = 2048;
  const shadowOffset = 0;

  const ref = useRef(null);
  // const refSpotLight = useLightPos(ref, radius, [-1, 1, 0.5]);

  return (
    <group
      position={[0, -0.5, 0]}
      ref={ref}
    >
      <fog
        color={0x000000}
        near={1}
        far={3}
      />

      <mesh rotation={[Math.PI * 0.5, 0, 0]}>
        <sphereGeometry args={[10, 32, 32, 0, Math.PI * 2, Math.PI * 0.5]} />
        <meshBasicMaterial
          color={'#000000'}
          side={DoubleSide}
        />
      </mesh>
      <group
        position={[0.02, 0.27, 0]}
        rotation={[
          MathUtils.degToRad(10),
          MathUtils.degToRad(25),
          MathUtils.degToRad(0),
        ]}
        scale={[0.8, 0.8, 0.8]}
      >
        <SpeederModel
          hideBike={true}
          hideFrame={true}
          clip={true}
        />
        <SpeederModel hideScenery={true} />
        <Environment preset={'forest'} />

        <spotLight
          penumbra={1}
          intensity={intensity}
          castShadow={true}
          shadow-bias={shadowBias}
          shadow-normalBias={normalBias}
          shadow-mapSize={shadowSize}
          position={[-1 * radius, 1 * radius, 0.5 * radius]}
        />

        {/* <pointLight
          ref={refPointLight}
          intensity={intensity / 2}
        /> */}

        {/* <PivotControls annotations>
          <pointLight intensity={intensity / 2} />
        </PivotControls> */}
      </group>

      <SpeederFrame />
    </group>
  );
}
