import { useRef } from 'react';
import { useMask, Environment, Stage } from '@react-three/drei';

import { EffectComposer, Bloom, Vignette } from '@react-three/postprocessing';

import { SpeederGroundModel } from './SpeederGroundModel';
import { Ground } from '@/components/ar/Ground';
import { XR8_Environment } from '@/components/ar/xr8/XR8_Environment';
import { useLightPos } from '@/components/ar/utils/useLightPos.jsx';
import Env from '@/helpers/Env';

const presets = {
  rembrandt: {
    main: [1, 2, 1],
    fill: [-2, -0.5, -2],
  },
  portrait: {
    main: [-1, 2, 0.5],
    fill: [-1, 0.5, -1.5],
  },
  upfront: {
    main: [0, 2, 1],
    fill: [-1, 0.5, -1.5],
  },
  soft: {
    main: [-2, 4, 4],
    fill: [-1, 0.5, -1.5],
  },
};

export function SpeederGroundScene(props) {
  // *******************************************************
  // STENCIL
  // *******************************************************
  const stencil = useMask(1);

  // *******************************************************
  // SPOT LIGHT
  // *******************************************************
  const intensity = 1;
  const config = presets.soft;
  const radius = 1;
  const shadows = 'contact';
  const shadowBias = -0.0001;
  const normalBias = 0;
  const shadowSize = 2048;
  const shadowOffset = 0;

  const { XR8 } = window;

  const ref = useRef(null);
  // const refSpotLight = useLightPos(ref, radius, config.main);
  // const refPointLight = useLightPos(ref, radius, config.fill);

  return (
    <group
      ref={ref}
      position={[0, 0, 0]}
    >
      <SpeederGroundModel enabled={props.enabled} />
      <Ground />

      <spotLight
        penumbra={1}
        intensity={intensity}
        castShadow={true}
        shadow-bias={shadowBias}
        shadow-normalBias={normalBias}
        shadow-mapSize={shadowSize}
        position={[
          config.main[0] * radius,
          config.main[1] * radius,
          config.main[2] * radius,
        ]}
      />
      <pointLight
        intensity={intensity / 3}
        position={[
          config.fill[0] * radius,
          config.fill[1] * radius,
          config.fill[2] * radius,
        ]}
      />
    </group>
  );
}
