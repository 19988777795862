export function Ground() {
  return (
    <>
      <mesh
        position={[0, -0.99, 0]}
        receiveShadow={true}
      >
        <boxGeometry args={[1000, 2, 1000]} />
        <shadowMaterial opacity={0.35} />
      </mesh>

      {/* <mesh
        position={[0, 0, 0]}
        rotation={[Math.PI * -0.5, 0, 0]}
        receiveShadow={true}
      >
        <planeGeometry args={[1000, 1000]} />
        <shadowMaterial opacity={0.2} />
      </mesh> */}
    </>
  );
}
