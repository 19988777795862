import { useMask, Environment, Stage } from '@react-three/drei';

import { EffectComposer, Bloom, Vignette } from '@react-three/postprocessing';
import { DoubleSide } from 'three';
import { ThroneGroundModel } from './ThroneGroundModel';
import { Ground } from '@/components/ar/Ground';
import { XR8_Environment } from '@/components/ar/xr8/XR8_Environment';
import { useLightPos } from '@/components/ar/utils/useLightPos.jsx';
import Env from '@/helpers/Env';

const presets = {
  rembrandt: {
    main: [1, 2, 1],
    fill: [-2, -0.5, -2],
  },
  portrait: {
    main: [-1, 2, 0.5],
    fill: [-1, 0.5, -1.5],
  },
  upfront: {
    main: [0, 2, 1],
    fill: [-1, 0.5, -1.5],
  },
  soft: {
    main: [-2, 4, 4],
    fill: [-1, 0.5, -1.5],
  },
};

export function ThroneGroundScene(props) {
  // *******************************************************
  // SPOT LIGHT
  // *******************************************************
  const intensity = 1;
  const config = presets.soft;
  const radius = 1;
  const shadows = 'contact';
  const shadowBias = -0.0001;
  const normalBias = 0;
  const shadowSize = 2048;
  const shadowOffset = 0;

  const { XR8 } = window;

  const ref = useRef(null);
  // const refSpotLight = useLightPos(ref, radius, [-0.5, 1, 0.5]);
  // const refPointLight = useLightPos(ref, radius, [-0.1, 0.175, -1]);

  return (
    <group
      position={[0, 0, 0]}
      ref={ref}
    >
      {/* <mesh rotation={[Math.PI * 0.5, 0, 0]}>
        <sphereGeometry args={[10000, 16, 16, 0, Math.PI * 2, Math.PI * 0.5]} />
        <meshBasicMaterial
          color={'#000000'}
          side={DoubleSide}
        />
      </mesh> */}
      <ThroneGroundModel
        showLuke={props.showLuke}
        enabled={props.enabled}
      />
      <Ground />

      {/* {Env.mobile ? <XR8_Environment /> : <Environment preset={'city'} />} */}
      {/* <XR8_Environment /> */}
      {/* <Environment preset={'city'} /> */}
      <spotLight
        penumbra={1}
        position={[
          // -0.5 * radius,
          // 1 * radius,
          // 0.5 * radius,
          config.main[0] * radius,
          config.main[1] * radius,
          config.main[2] * radius,
        ]}
        intensity={intensity}
        castShadow={true}
        shadow-bias={shadowBias}
        shadow-normalBias={normalBias}
        shadow-mapSize={shadowSize}
      />
      {/* <pointLight
        position={[
          // -0.1 * radius,
          // 0.175 * radius,
          // -1 * radius,
          config.fill[0] * radius,
          config.fill[1] * radius,
          config.fill[2] * radius,
        ]}
        intensity={intensity / 3}
      /> */}
    </group>
  );
}
